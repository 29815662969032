import Constants from "@/uitls/Constants";

const REGISTER_API = '/api/v1/register'
const AUTH_API = '/api/v1/login'
const FICKS_ACTIVATE_API = '/api/v1/ficks/user/activate'
const FICKS_USER_STATUS_API = '/api/v1/ficks/user/status'
const SIGNOUT_API = '/api/v1/logout'
const WITHDRAWAL_API = '/api/v1/withdrawal'
const USER_API = '/api/v1/user'
const SHOP_API = '/api/v1/shop'
const SHOP_BUSINESSHOURS_API = '/api/v1/shop/schedule/policy'
const SHOP_PICTURES_API = '/api/v1/shop/pictures'
const SEARCH_SHOP_API = '/api/v1/search/shop'
const LOCATION_SEARCH_SHOP_API = '/api/v1/location_search/shop'
const MYPAGE_API = '/api/v1/mypage'
const COUPONS_API = '/api/v1/coupon'
const EXCHANGE_COUPON_API = '/api/v1/coupon/exchange'
const NOTIFICATION_API = '/api/v1/notification'
// const WORDPRESS_CATEGORIES_API = `${process.env.baseWordpressURL}/wp-json/wp/v2/categories`
// const WORDPRESS_POSTS_API = 'http://media.beauty-360.com//wp-json/wp/v2/posts'
// const WORDPRESS_TAGS_API = `${process.env.baseWordpressURL}/wp-json/wp/v2/tags`
const WORDPRESS_POSTS_API = "/posts"
const WORDPRESS_CATEGORIES_API = "/categories"
const WORDPRESS_TAGS_API = "/tags"
const SEARCH_COUPON_API = '/api/v1/search/coupon'
const CATEGORY_API = '/api/v1/category'
const PRODUCT_API = '/api/v1/product'
const INQUIRY_API = '/api/v1/inquiry'
const ONETIME_TOKEN_API = '/api/v1/token'
const CAMPAIGN_API = '/api/v1/campaign'
const CUSTOM_TOKEN_API = '/api/v1/custom_token'
const MISSION_API = '/api/v1/mission'
const FORM_API = '/api/v1/form'
const POINT_API = '/api/v1/point'
const INTEREST_API = '/api/v1/interest'
const TROUBLE_API = '/api/v1/trouble'
const MEMBERSHIP_API = '/api/v1/membership'
const MEMBERSHIP_TICKET_API = '/api/v1/membership/ticket'
const STAFF_API = '/api/v1/staff'
const STAFF_PICTURES_API = '/api/v1/staff_picture'
const MENU_API = '/api/v1/menu'
const LINE_COUPON_SETTINGS_API = '/api/v1/line_coupon_settings'
const NOMINATION_MENU_API = '/api/v1/nomination_menu'
const USER_RESERVATION_API = '/api/v1/user/reservation'
const USER_RESERVATION_API_V2 = '/api/v2/user/reservation'
const PREFECTURE_API = '/api/v1/prefecture'
const USER_WITHDRAWAL_API = '/api/v1/user/withdrawal'
const USER_MEMBERSHIP_API_V2 = '/api/v2/user/membership'
const CROWDFUNDING_API = '/api/v1/crowdfunding'
const CROWDFUNDING_SALESORDER_API = '/api/v1/crowdfundingsalesorder'
const USER_CROWDFUNDING_API = '/api/v1/user/crowdfunding'

const PREFECTURE_API_V2 = '/api/v2/prefectures'
const SHOP_API_V2 = '/api/v2/shop'

export {
  REGISTER_API,
  AUTH_API,
  FICKS_ACTIVATE_API,
  FICKS_USER_STATUS_API,
  SIGNOUT_API,
  USER_API,
  SHOP_API,
  SHOP_BUSINESSHOURS_API,
  SHOP_PICTURES_API,
  MYPAGE_API,
  COUPONS_API,
  EXCHANGE_COUPON_API,
  NOTIFICATION_API,
  SEARCH_SHOP_API,
  LOCATION_SEARCH_SHOP_API,
  WORDPRESS_CATEGORIES_API,
  WORDPRESS_POSTS_API,
  WORDPRESS_TAGS_API,
  SEARCH_COUPON_API,
  CATEGORY_API,
  WITHDRAWAL_API,
  PRODUCT_API,
  INQUIRY_API,
  ONETIME_TOKEN_API,
  CAMPAIGN_API,
  CUSTOM_TOKEN_API,
  MISSION_API,
  FORM_API,
  POINT_API,
  INTEREST_API,
  TROUBLE_API,
  MEMBERSHIP_API,
  MEMBERSHIP_TICKET_API,
  STAFF_API,
  STAFF_PICTURES_API,
  MENU_API,
  LINE_COUPON_SETTINGS_API,
  NOMINATION_MENU_API,
  USER_RESERVATION_API,
  USER_RESERVATION_API_V2,
  PREFECTURE_API,
  USER_WITHDRAWAL_API,
  USER_MEMBERSHIP_API_V2,
  CROWDFUNDING_API,
  CROWDFUNDING_SALESORDER_API,
  USER_CROWDFUNDING_API,
  PREFECTURE_API_V2,
  SHOP_API_V2,
}
