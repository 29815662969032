
import firebase from "@/plugins/firebase";
import utils from "@/uitls/Uitls";

const state = {
  sponsoreList: [],
};

const getters = {
  getsponsoreList: (state) => state.sponsoreList,
};

const mutations = {
  SET_DATA: (state, data) => {
    state.sponsoreList = data;
  },
};

const actions = {
  async sponsorInfo({ commit, state, dispatch }, data) {

    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 0, //3600000
    };

    remoteConfig
      .ensureInitialized()
      .then(() => {
        remoteConfig
          .fetchAndActivate()
          .then(() => {
            const stringJson = remoteConfig.getString("top_page_banner");
            if (!utils.isEmpty(stringJson)) {
              commit("SET_DATA", JSON.parse(stringJson))
            }
          })
          .catch((err) => {
            console.error(
              "Firebase Remote Config failed to fetchAndActivate",
              err
            );
          });
      })
      .catch((err) => {
        console.error("Firebase Remote Config failed to initialize", err);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
