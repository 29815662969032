<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-skeleton-loader
      type="image, article"
      v-if="loadingDialog"
    ></v-skeleton-loader>

    <v-sheet v-if="shopData != null">
      <div ref="sections">
        <v-toolbar
          color="#FAFAFA"
          v-show="showTabs"
          style="position: sticky; top: 0; z-index: 1000"
          density="compact"
          extension-height="48px"
          flat
          elevation="1"
        >
          <v-toolbar-title
            class="text-body-2 mx-2"
            style="white-space: normal; overflow: visible; font-weight: bold"
          >
            {{ shopData.name }}
          </v-toolbar-title>

          <v-btn
            :icon="this.isLikedFavorite ? 'mdi-heart' : 'mdi-heart-outline'"
            :color="this.isLikedFavorite ? 'green' : 'black'"
            :ripple="false"
            size="small"
            @click="favoriteOnClick()"
          ></v-btn>

          <v-btn
            icon="mdi-tray-arrow-up"
            :ripple="false"
            size="small"
            @click="snsShare"
          ></v-btn>

          <template v-slot:extension>
            <v-tabs
              v-model="active_tab"
              bg-color="#FAFAFA"
              grow
              center-active
              fixed-tabs
              show-arrows
              density="compact"
            >
              <v-tab :value="i" v-for="i in tabs" :key="i" :href="`#tab-${i}`">
                <p class="text-caption">{{ $t("shop." + i) }}</p>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <section
          v-for="i in tabs"
          :key="i"
          :id="'tab-' + i"
          class="tab-section"
        >
          <v-card elevation="0" class="mt-0 pa-0" v-if="i === 'gallery'">
            <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" />

            <v-layout class="ma-0 mt-0" v-if="shopPictures.length > 0">
              <v-carousel
                :height="this.isMobileDevice.value ? 280 : 500"
                width="100%"
                hide-delimiters
                color="grey-darken-1"
                :show-arrows="false"
                @input="onSlideChange"
                v-model="currentSlide"
              >
                <v-carousel-item
                  v-for="picture in shopPictures"
                  :key="picture"
                  cover
                  eager
                  @click="galleryOnClick"
                >
                  <v-img
                    cover
                    :src="picture.big"
                    :lazy-src="local_logo"
                    height="100%"
                  />
                </v-carousel-item>

                <v-btn
                  flat
                  rounded="xl"
                  size="small"
                  color="black"
                  @click="galleryOnClick"
                  style="position: absolute; bottom: 16px; right: 16px"
                >
                  <p class="text-caption">
                    {{ currentSlide + 1 }} / {{ shopPictures.length }}
                  </p>
                </v-btn>
              </v-carousel>
              <div
                class="ribbon ribbon-top-right"
                v-if="crowdfundings !== undefined && crowdfundings.length > 0"
              >
                <span class="text-body-1">{{ $t("homeview.voucher") }}</span>
              </div>
            </v-layout>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="8">
                  <h1 class="text-h6" style="font-weight: bold">
                    <v-icon
                      v-if="shopData.is_official"
                      icon="mdi-check-decagram"
                      size="16"
                      color="blue"
                      class="mt-n1"
                    />
                    {{ shopData.name }}
                  </h1>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2"></v-col>
                <v-btn
                  class="mt-n2"
                  elevation="0"
                  :ripple="false"
                  :icon="
                    this.isLikedFavorite ? 'mdi-heart' : 'mdi-heart-outline'
                  "
                  :color="this.isLikedFavorite ? 'green' : 'black'"
                  variant="text"
                  @click="favoriteOnClick"
                ></v-btn>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="7">
                  <div class="mx-0">
                    <v-icon
                      icon="mdi-briefcase"
                      color="black"
                      size="x-small"
                      class="mx-0"
                    />
                    <span
                      style="font-size: 12px; font-weight: normal"
                      class="mx-2 text-black"
                    >
                      {{ this.todayWorkHours }}
                    </span>
                  </div>
                </v-col>

                <v-col cols="5">
                  <div class="mx-0">
                    <v-icon
                      icon="mdi-cash"
                      color="black"
                      size="x-small"
                      class="mx-0"
                    />
                    <span
                      style="font-size: 12px; font-weight: normal"
                      class="mx-2 text-black"
                    >
                      {{ shopPriceRange }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="7">
                  <div class="mx-0">
                    <v-icon
                      icon="mdi-star"
                      color="black"
                      size="16"
                      class="mx-0"
                    />
                    <span
                      style="font-size: 12px; font-weight: normal"
                      class="mx-2 text-black"
                      v-if="shopDetail.os_rating"
                    >
                      {{ shopDetail.os_rating }} ({{ shopDetail.os_reviews }})
                    </span>
                    <span
                      v-else
                      style="font-size: 12px; font-weight: normal"
                      class="mx-2 text-black"
                    >
                      {{ $t("searchresults.no_review") }}
                    </span>
                  </div>
                </v-col>

                <v-col cols="5">
                  <div class="mx-0">
                    <v-icon
                      icon="mdi-heart"
                      color="black"
                      size="16"
                      class="mx-0"
                    />
                    <span
                      style="font-size: 12px; font-weight: normal"
                      class="mx-2 text-black"
                    >
                      {{ this.shopData.favorite_count }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!--AIReview -->
          <v-sheet class="ma-4 mt-12" v-if="i === 'ai_highlights'">
            <!--AIReview Icon-->
            <v-list-item class="mx-n4">
              <template v-slot:prepend>
                <v-img :src="bot_logo_src" :width="22" />
              </template>
              <p class="text-h6 mx-2 mt-0" style="font-weight: bold">
                {{ $t("shop.ai_highlights") }}
              </p>
            </v-list-item>

            <v-sheet class="ma-0" v-if="!checkEmpty(shopDetail.ai_highlight)">
              <div
                class="text-body-1 mt-1"
                style="font-weight: normal"
                v-if="this.$i18n.locale == 'zhCN'"
              >
                <p v-if="!readActivated" style="font-weight: normal">
                  {{ shopDetail.ai_highlight_zhcn.slice(0, 100) }}...
                  <span
                    class="mt-4 text-blue"
                    v-if="!readActivated"
                    @click="activateReadMore"
                    style="font-weight: bold"
                    >{{ $t("homeview.show_more") }}</span
                  >
                </p>
                <ShopAbout
                  v-if="readActivated"
                  @chipTagOnClickCallback="chipTagOnClickCallback"
                  :shopDetail="shopData"
                />
              </div>

              <div
                class="text-body-1 mt-1"
                style="font-weight: normal"
                v-else-if="this.$i18n.locale == 'ms'"
              >
                <p v-if="!readActivated" style="font-weight: normal">
                  {{ this.shopDetail.ai_highlight_ms.slice(0, 200) }}...
                  <span
                    class="mt-4 text-blue"
                    v-if="!readActivated"
                    @click="activateReadMore"
                    style="font-weight: bold"
                    >{{ $t("homeview.show_more") }}</span
                  >
                </p>
                <ShopAbout
                  v-if="readActivated"
                  @chipTagOnClickCallback="chipTagOnClickCallback"
                  :shopDetail="shopData"
                />
              </div>

              <div class="text-body-1 mt-1" style="font-weight: normal" v-else>
                <p v-if="!readActivated" style="font-weight: normal">
                  {{ this.shopDetail.ai_highlight.slice(0, 200) }}...
                  <span
                    class="mt-4 text-blue"
                    v-if="!readActivated"
                    @click="activateReadMore"
                    style="font-weight: bold"
                    >{{ $t("homeview.show_more") }}</span
                  >
                </p>
                <ShopAbout
                  v-if="readActivated"
                  @chipTagOnClickCallback="chipTagOnClickCallback"
                  :shopDetail="shopData"
                />
              </div>
            </v-sheet>
          </v-sheet>

          <!-- ShopPriceList -->
          <v-sheet elevation="0" class="ma-4 mt-12" v-if="i === 'menu_promo'">
            <!--Promotions -->
            <v-sheet
              elevation="0"
              class="ma-0 mt-6"
              v-if="crowdfundings.length > 0"
            >
              <v-list-item class="mx-n4">
                <template v-slot:prepend>
                  <v-img src="/discounts.png" :width="22" />
                </template>
                <p class="text-h6 mx-2 mt-0" style="font-weight: bold">
                  {{ $t("homeview.special_voucher") }}
                </p>
              </v-list-item>

              <CrowdfundingsCard
                :crowdfundingsObjects="crowdfunding_items"
                :showAllItem="true"
              />
              <v-row>
                <v-col class="text-center">
                  <v-btn
                    width="40%"
                    elevation="0"
                    class="ma-6 border"
                    height="36px"
                    :ripple="false"
                    size="small"
                    rounded="pill"
                    @click="showMoreOnClick('voucher')"
                    >{{ $t("homeview.show_more") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-sheet>

            <v-list-item class="mx-n4">
              <template v-slot:prepend>
                <v-img
                  :src="require('@/assets/img/online-shop.png')"
                  :width="22"
                />
              </template>
              <p class="text-h6 mx-2 mt-0" style="font-weight: bold">
                {{ $t("shop.menu_promo") }}
              </p>
              <p
                v-if="!shopData.is_official"
                class="text-caption text-grey mx-2 mt-n2"
                style="font-weight: normal"
              >
                {{ $t("shopprice.onlyForReference") }}
              </p>
            </v-list-item>

            <ShopPriceList
              v-if="shopData.menus"
              :shopData="shopData"
              :showMinimum="true"
            />
            <v-row v-if="shopData.menus?.length > 1">
              <v-col class="text-center">
                <v-btn
                  width="40%"
                  elevation="0"
                  class="ma-6 border"
                  height="36px"
                  :ripple="false"
                  size="small"
                  rounded="pill"
                  @click="showMoreOnClick('menu')"
                  >{{ $t("homeview.show_more") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-sheet>

          <!-- ShopStaffList -->
          <v-sheet elevation="0" class="ma-4 mt-6" v-if="i === 'stylist'">
            <v-list-item class="mx-n4">
              <template v-slot:prepend>
                <v-img :src="require('@/assets/img/stylist.png')" :width="22" />
              </template>
              <p class="text-h6 mx-2 mt-0" style="font-weight: bold">
                {{ $t("shop.stylist") }}
              </p>
            </v-list-item>

            <ShopStaffList
              v-if="shopData.staffs != null"
              :staffs="shopData ? shopData.staffs : []"
            />
          </v-sheet>

          <!--Shop Details  -->
          <v-sheet
            elevation="0"
            class="ma-4 mt-6"
            v-if="i === 'about_shop_information'"
          >
            <v-list-item class="mx-n4">
              <template v-slot:prepend>
                <v-img :src="require('@/assets/img/store.png')" :width="22" />
              </template>
              <p class="text-h6 mx-2 mt-0" style="font-weight: bold">
                {{ $t("shop.about_shop_information") }}
              </p>
            </v-list-item>

            <ShopMenuList
              v-if="shopData.detail != null"
              :shopObjects="shopData"
            />

            <!-- ArticleList -->
            <RelativeArticle
              class="mt-6"
              v-if="shopData?.topics?.length > 0"
              :shopName="shopData.name"
              :articleObjects="shopData.topics"
              @articleIDCallBack="articleIDCallBack"
            ></RelativeArticle>

            <!-- Instagram -->
            <!-- <div v-if="shopData.detail?.instagram != null" align="center">
            <iframe
              :width="this.isMobileDevice.value ? '320' : '500'"
              :height="this.isMobileDevice.value ? '420' : '560'"
              :src="generateInstaUrl(shopData.detail?.instagram)"
              frameborder="0"
            ></iframe>
          </div> -->
          </v-sheet>
        </section>
      </div>

      <!-- About Shop Information -->
      <ReservationGroup class="ma-2" />

      <!-- NearThisShop -->
      <ShopNearByShop
        class="mt-12"
        v-if="shopNearByShopCoordinates != null"
        :coordinates="shopNearByShopCoordinates"
      />

      <!-- LocationShopList -->
      <v-sheet class="mt-12" v-if="haveCurrentCoordinates != null">
        <LocationShopList />
      </v-sheet>

      <!-- Sponsor Shop -->
      <v-sheet class="mt-6 mb-12">
        <SponsorBanner
          v-if="getsponsoreList.length > 0 && timerShow"
          :itemObjects="getsponsoreList"
        />
      </v-sheet>

      <GalleryList
        v-if="showGallryList"
        :id="shopData.id"
        :shopName="shopData.name"
        @closeGalleryList="closeGalleryList"
      />

      <v-bottom-sheet inset v-model="show_bottom_sheet">
        <ShopPriceList
          v-if="shopData.menus && show_bottom_sheet_mode === 'menu'"
          :shopData="shopData"
          :showMinimum="false"
        />
      </v-bottom-sheet>

      <v-dialog v-model="callDialog" width="auto">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("shop.before_making") }}
          </v-card-title>
          <v-card-text>
            {{ $t("shop.pls_tell_store") }}
            <span class="text-h6" style="font-weight: bold">Beauty360</span>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="makeACall">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <SNSShareDialog
        v-if="shopData.detail != null"
        :shopInfoObjects="shopData"
        :openSNSShareDialog="openSNSShareDialog"
        @result="responseFromSNSDialog"
      ></SNSShareDialog>

      <VSonner position="top-center" />

      <!-- Footer -->
      <v-bottom-navigation :active="bottomBtnActive" grow bg-color="white">
        <div class="mt-4 mx-4">
          <v-icon class="mt-n1" icon="mdi-briefcase" color="black" />
          <span
            style="font-size: 14px; font-weight: normal"
            class="mx-2 text-black"
          >
            {{ this.todayWorkHours }}
          </span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="custom-btn-color"
          prepend-icon="mdi-phone"
          :ripple="false"
          @click="callDialog = true"
          variant="flat"
          size="small"
          stacked
          max-width="120px"
        >
          <p style="font-weight: bold" class="text-white">
            {{ $t("shop.call_reserve") }}
          </p>
        </v-btn>

        <!--TMP: Only shop1 can online reserve -->
        <v-btn
          v-if="shopData.company_appointment_setting && shopData.id == 1"
          prepend-icon="mdi-calendar-check"
          class="ma-2"
          variant="outlined"
          rounded="lg"
          height="40px"
          max-width="120px"
          :ripple="false"
          :to="{ name: 'ReservationForm', params: { id: shopData.id } }"
        >
          <p style="font-weight: bold">{{ $t("shop.online_reserve") }}</p>
        </v-btn>
      </v-bottom-navigation>
    </v-sheet>

    <!-- PageNotFound -->
    <NotFoundView v-if="showPageNotFound"></NotFoundView>

    <LoginDialog
      v-if="showLoginDialog"
      @loginDialogCallBack="loginDialogCallBack"
    ></LoginDialog>
  </v-sheet>
</template>

<script>
import { useHead } from "@vueuse/head";
import TopStyleList from "@/components/TopStyleList.vue";
import ShopMenuList from "@/components/ShopMenuList.vue";
import ShareWithFriend from "@/components/ShareWithFriend.vue";
import ReservationGroup from "@/components/ReservationGroup.vue";
import ShopGallery from "@/components/ShopGallery.vue";
import ShopAbout from "@/components/ShopAbout.vue";
import ShopPriceList from "@/components/ShopPriceList.vue";
import MapOnly from "@/components/MapOnly.vue";
import ShopStaffList from "@/components/ShopStaffList.vue";
import CuponSheet from "@/components/CuponSheet.vue";
import SNSShareDialog from "@/components/SNSShareDialog.vue";
import NotFoundView from "./NotFoundView.vue";
import Constants from "@/uitls/Constants";
import { VSonner, toast } from "vuetify-sonner";
import { mapState, mapActions, mapGetters } from "vuex";
import Carousel from "@/uitls/Carousel.vue";
import CrowdfundingsCard from "@/components/CrowdfundingsCard.vue";
import utils from "@/uitls/Uitls.js";
import LocationShopList from "@/components/LocationShopList.vue";
import ShopNearByShop from "@/components/ShopNearByShop.vue";
import SponsorBanner from "@/components/Banner/SponsorBanner.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import GalleryList from "@/components/GalleryList.vue";
import RelativeArticle from "@/components/RelativeArticle.vue";
import LoginDialog from "@/components/LoginDialog.vue";

export default {
  props: ["shop_slug"],

  components: {
    TopStyleList,
    ShopMenuList,
    ShareWithFriend,
    ReservationGroup,
    ShopGallery,
    ShopAbout,
    ShopPriceList,
    MapOnly,
    ShopStaffList,
    CuponSheet,
    SNSShareDialog,
    VSonner,
    Carousel,
    CrowdfundingsCard,
    LocationShopList,
    ShopNearByShop,
    SponsorBanner,
    NotFoundView,
    Breadcrumbs,
    GalleryList,
    RelativeArticle,
    LoginDialog,
  },

  setup() {},

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      item: "authStore/item",
      userInfo: "userStore/userInfo",
    }),
    ...mapState({
      getsponsoreList: (state) => state.SponsorStore.sponsoreList,
    }),
  },

  watch: {
    isAuthenticated(to, from) {},
    $route(to, from) {
      this.resetValue();
      this.parseShopV2();
    },
  },

  async beforeMount() {
    await this.parseShopV2();
  },

  data() {
    return {
      tabs: [
        "gallery",
        "ai_highlights",
        "menu_promo",
        "stylist",
        "about_shop_information",
      ],
      showTabs: false, // Initially hide the tabs
      lastScrollPosition: 0, // Track the last scroll position

      showGallryList: false,
      currentSlide: 0,
      show_bottom_sheet_mode: "",
      show_bottom_sheet: false,

      loadingDialog: true,
      readActivated: false,
      shopData: {
        shop_slug: this.shop_slug,
      },
      shopDetail: {
        station: null,
        phone: null,
      },
      shopPictures: [],
      crowdfundings: [],
      crowdfunding_items: [],
      favorites: [],
      isLikedFavorite: false,
      showMoreHighLight: false,
      bot_logo_src: require("@/assets/img/robot_120.png"),
      callDialog: false,
      bottomBtnActive: false,
      active_tab: "tab-gallery",
      openSNSShareDialog: false,
      haveCurrentCoordinates: null,
      shopNearByShopCoordinates: null,
      todayWorkHours: null,

      businessHours: null,
      shopPriceRange: "RM-",
      timerShow: false,
      showPageNotFound: false,

      breadCrumbsProps: [],
      showLoginDialog: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.createObserver();
    window.addEventListener("scroll", this.handleScroll.bind(this));
  },

  beforeDestroy() {
    if (this.observer) this.observer.disconnect();
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  },

  methods: {
    createObserver() {
      const options = {
        root: null,
        threshold: 0.5, // Trigger when 50% of the section is visible
      };

      this.observer = new IntersectionObserver(this.handleIntersect, options);
      this.$refs.sections
        .querySelectorAll(".tab-section")
        .forEach((section) => {
          this.observer.observe(section);
        });
    },

    handleIntersect(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.active_tab = entry.target.id;
          // console.log("active_tab: " + this.active_tab);
        }
      });
    },

    handleScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      // Show tabs when scrolling down or up, but hide them at the top
      this.showTabs = currentScrollPosition > 40;
      this.lastScrollPosition = currentScrollPosition;

      this.bottomBtnActive = this.showTabs;
    },

    checkEmpty(obj) {
      return utils.isEmpty(obj) ? true : false;
    },

    activateReadMore() {
      this.readActivated = true;
    },

    generateInstaUrl(value) {
      if (!value) {
        console.error("Instagram handle is missing");
        return "";
      }

      let queryString = value;
      if (queryString.includes("/?hl=en")) {
        queryString = queryString.replace("/?hl=en", "");
      }
      if (queryString.endsWith("/")) {
        queryString = queryString.slice(0, -1); // Remove the last character
      }
      queryString = queryString + "/embed";
      return queryString;
    },

    resetValue() {
      (this.shopData = {
        shop_slug: this.shop_slug,
      }),
        (this.shopDetail = {
          station: null,
          phone: null,
        }),
        (this.shopPictures = []),
        (this.crowdfundings = []),
        (this.crowdfunding_items = []),
        (this.favorites = []),
        (this.isLikedFavorite = false),
        (this.showMoreHighLight = false),
        (this.totalPhotoCount = 0),
        (this.active_tab = "tab-1"),
        (this.shortcutList = ["Hair Cut", "Color", "Prem"]),
        (this.openSNSShareDialog = false),
        (this.haveCurrentCoordinates = null),
        (this.readActivated = false),
        (this.breadCrumbsProps = []),
        (this.shopNearByShopCoordinates = null);
    },

    showMoreOnClick(value) {
      if (value === "voucher") {
        this.$router.push({
          name: "CrowdfundingsDetail",
          params: { crowdfundingId: this.crowdfunding_items.id },
        });
      } else {
        this.show_bottom_sheet_mode = value;
        this.show_bottom_sheet = true;
      }
    },

    async parseShopV2() {
      await this.$store
        .dispatch("shopStore/fetchShopV2", this.shop_slug)
        .then(async (res) => {
          if (res) {
            this.shopData = res;
            this.showPageNotFound = false;

            if (this.shopData.detail) {
              this.shopDetail = this.shopData.detail;
            }
            if (this.shopData.crowdfundings) {
              this.crowdfundings = this.shopData.crowdfundings;
              if (this.crowdfundings.length > 0) {
                await this.fetchCrowdfunding(this.crowdfundings[0].id);
              }
            }
            if (this.shopData.pictures) {
              this.totalPhotoCount = this.shopData.pictures.length;
              if (this.shopData.pictures.length > 0) {
                this.shopData.pictures.forEach((v, i) => {
                  this.shopPictures.push({
                    id: i,
                    big: v.picture_url,
                    thumb: v.picture_url_small,
                  });
                });
              }
            }
            if (this.shopData.schedule_policy) {
              this.businessHours = this.shopData.schedule_policy;
              this.handleShopBusinessHour();
            }
            this.shopPriceRange = utils.calculateShopPriceRange(this.shopData);

            this.handleBreadcrumbs();
          } else {
            this.shopData = {};
            this.showPageNotFound = true;
          }
        })
        .catch((e) => {
          console.error(e);
          this.$router.push("/404");
        })
        .finally(() => {
          this.loadingDialog = false;
        });

      this.haveCurrentCoordinates = this.$cookies.get("coordinates");

      if (this.isAuthenticated) {
        this.fetchMypageFavorites();
      }

      this.shopNearByShopCoordinates = {
        shop_id: this.shopData.id,
        latitude: this.shopData.latitude,
        longitude: this.shopData.longitude,
      };

      this.setMetaInfo();

      setTimeout(() => (this.timerShow = true), 500);
    },

    setMetaInfo() {
      useHead({
        title:
          this.shopData.name +
          " | " +
          this.$i18n.t("homeview.find_your_salon") +
          "Beauty 360",
        meta: [
          {
            name: "description",
            content: this.shopDetail ? this.shopDetail.ai_review_short : "",
          },
          {
            name: "keywords",
            content: utils.removeArraySymbleForKeyword(
              this.shopData.tags.toString()
            ),
          },
          {
            property: "og:title",
            content:
              this.shopData.name +
              " | " +
              this.$i18n.t("homeview.find_your_salon") +
              "Beauty 360",
          },
          {
            property: "og:description",
            content: this.shopDetail ? this.shopDetail.ai_review_short : "",
          },
          {
            property: "og:url",
            content: "https://beauty-360.com/" + this.shopData.shop_slug,
          },
          {
            property: "og:image",
            content:
              this.shopData.pictures && this.shopData.pictures.length > 0
                ? this.shopData.pictures[0].picture_url
                : "",
          },
          {
            property: "og:image:alt",
            content: this.shopData.name + " | Beauty 360",
          },
        ],
      });
    },

    handleBreadcrumbs() {
      const vm = this;
      // console.log('this.shopData', this.shopData);
      // console.log('this.shopDetail', this.shopDetail);
      vm.breadCrumbsProps = {
        data: [
          {
            name: "mypage.topPage",
            to: "/",
            disabled: false,
          },
          {
            name: "utls.hair_salon",
            to: "/hairsalon/searchresult",
            disabled: false,
          },
        ],
      };

      if (vm.shopData.prefecture_v2) {
        console.log(vm.shopData.prefecture_v2.id);
        vm.breadCrumbsProps.data.push({
          name: "prefecture." + vm.shopData.prefecture_v2.code,
          // to: "/hairsalon/" + vm.shopData.prefecture_v2.name,
          to:
            "/hairsalon/searchresult?query=" +
            encodeURIComponent(
              JSON.stringify({
                searchKeyword: null,
                prefecture_v2: vm.shopData.prefecture_v2.id,
              })
            ),
          disabled: false,
        });
      }

      if (vm.shopData.city) {
        vm.breadCrumbsProps.data.push({
          name: "city." + vm.shopData.city.code,
          // to:
          //   "/hairsalon/" +
          //   vm.shopData.prefecture_v2.name +
          //   "/" +
          //   vm.shopData.city.name,
          to:
            "/hairsalon/searchresult?query=" +
            encodeURIComponent(
              JSON.stringify({
                searchKeyword: null,
                prefecture_v2: vm.shopData.prefecture_v2.id,
                city_id: vm.shopData.city.id,
              })
            ),
          disabled: false,
        });
      }

      const allQueries = vm.$route.query;
      Object.entries(allQueries).forEach(([key, value]) => {
        switch (key) {
          case "topic":
            // const targetTopic = vm.shopData.topics.find(item => item.id == value)
            // if (targetTopic) {
            //   vm.breadCrumbsProps.data.push({
            //     name: targetTopic['title_' + this.$i18n.locale],
            //     to: targetTopic['url_' + this.$i18n.locale],
            //     disabled: false,
            //   })
            // }
            vm.breadCrumbsProps.data.push({
              name: value,
              to: "/topic/" + value,
              disabled: false,
            });
            break;
          case "menu":
            const targetMenu = vm.shopData.menus.find(
              (item) => item.id == value
            );
            if (targetMenu) {
              // vm.breadCrumbsProps.data.push({
              //   name: 'city.' + vm.shopData.city.code,
              //   to: '/shop/' + this.shopData.id,
              //   disabled: true,
              // })
            }
            break;
        }
      });

      vm.breadCrumbsProps.data.push({
        name: vm.shopData.name,
        disabled: true,
      });
    },

    generateBreadcumbs() {
      this.breadCrumbsProps = [
        {
          title: this.$i18n.t("crowdfunding.home"),
          disabled: false,
          to: { name: "HomeView" },
        },
        {
          title: this.$i18n.t("shop.prefecture"),
          disabled: false,
          to: { name: "HomeView" },
        },
        {
          title: this.$i18n.t("shop.city"),
          disabled: false,
          to: { name: "HomeView" },
        },
        {
          title: this.shopData.name,
          disabled: true,
        },
      ];
    },

    async fetchCrowdfunding(crowdId) {
      await this.$store
        .dispatch("crowdStore/fetchCrowd", crowdId)
        .then((response) => {
          this.crowdfunding_items = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async fetchMypageFavorites() {
      const params = {
        id: this.userInfo.id,
        target_type: Constants.FAVORITE_TYPE_SHOP,
        page: 1,
        per_page: 300,
      };

      await this.$store
        .dispatch("userStore/fetchFavorites", params)
        .then((response) => {
          this.favorites = response.data;
          this.chceckHasFavorite();
        })
        .catch((error) => {});
    },

    chceckHasFavorite() {
      var tmp = this.favorites.filter((e) => e.target_id == this.id);
      if (tmp.length > 0) {
        this.isLikedFavorite = true;
      } else {
        this.isLikedFavorite = false;
      }
    },

    favoriteOnClick() {
      if (!this.isAuthenticated) {
        this.showLoginDialog = true;
        // toast(this.$i18n.t("shop.pls_login"), {
        //   cardProps: {
        //     color: "info",
        //     class: "my-toast",
        //   },
        // });
        return;
      }

      const params = {
        target_type: Constants.FAVORITE_TYPE_SHOP,
        target_id: this.id,
        user_id: this.item.user.id,
      };

      this.isLikedFavorite = !this.isLikedFavorite;
      if (this.isLikedFavorite) {
        this.addFavorite(params);
      } else {
        this.deleteFavorite(params);
      }
    },

    addFavorite(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("userStore/addFavorite", params)
          .then((response) => {
            resolve(response);
            toast(this.$i18n.t("shop.fav_added"), {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteFavorite(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("userStore/deleteFavorite", params)
          .then((response) => {
            resolve(response);
            toast(this.$i18n.t("shop.fav_remove"), {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    onSlideChange(index) {
      this.currentSlide = index;
    },

    getShopPrefectureTitle(value) {
      const prefectureTitle = this.$i18n.t(utils.getShopPrefectureTitle(value));

      if (value.city && value.city.code) {
        const cityTitle = this.$i18n.t(`city.${value.city.code}`);
        return `${prefectureTitle}, ${cityTitle}`;
      }
      return prefectureTitle;
    },

    handleShopBusinessHour() {
      if (this.businessHours) {
        const d = new Date();
        const dayIndex = d.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

        // Day labels based on the day of the week
        const dayLabels = [
          this.$i18n.t("shop.sun"),
          this.$i18n.t("shop.mon"),
          this.$i18n.t("shop.tue"),
          this.$i18n.t("shop.wed"),
          this.$i18n.t("shop.thu"),
          this.$i18n.t("shop.fri"),
          this.$i18n.t("shop.sat"),
        ];

        // Get the corresponding opening and closing times for the day
        const openingTime = this.businessHours[`opening_time_${dayIndex}`];
        const closingTime = this.businessHours[`closing_time_${dayIndex}`];

        // If opening time is empty, mark the shop as closed; otherwise, format the hours
        this.todayWorkHours =
          dayLabels[dayIndex] +
          " " +
          (utils.isEmpty(openingTime)
            ? this.$i18n.t("shop.closed")
            : `${utils.formatAMPM(
                new Date(Date.parse("Thu, 01 Jan 1970 " + openingTime))
              )} ~ ${utils.formatAMPM(
                new Date(Date.parse("Thu, 01 Jan 1970 " + closingTime))
              )}`);
      }
    },

    makeACall() {
      this.callDialog = false;
      window.open("tel:" + this.shopDetail.phone, "_self");
    },

    snsShare() {
      this.openSNSShareDialog = true;
    },

    responseFromSNSDialog() {
      this.openSNSShareDialog = false;
    },

    galleryOnClick() {
      this.showGallryList = true;
      console.log(
        "galleryOnClick " + this.showGallryList + "  - " + this.shopData.id
      );
    },

    closeGalleryList() {
      this.showGallryList = false;
    },

    loginDialogCallBack(value) {
      this.showLoginDialog = false;
      if (value) {
        this.$router.push({
          name: "LoginPage",
        });
      }
    },

    articleIDCallBack(articleID) {
      this.$router.push({
        name: "ArticleViewDetail",
        params: { id: parseInt(articleID) },
      });
    },
  },
};
</script>

<style scoped>
.custom-btn-color {
  background-color: #4caf50 !important; /* Custom color */
  color: #ffffff !important; /* Text color */
}

.tabs-container {
  position: relative;
}

.tab-section {
  /* margin-bottom: 20px; */
}
.tdLeft {
  width: 100%;
  min-width: 200px;
  table-layout: fixed;
  text-align: left;
  /* border: 1px solid black; */
  padding: 0px;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid red;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  background-color: red;
  color: #fff;
  font: 500 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 22px;
}

.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}

.ribbon-top-right span {
  left: -27px;
  top: 29px;
  transform: rotate(45deg);
}
</style>
