<template>
  <div v-if="voucherNotFound">
    <v-sheet elevation="0" max-width="600" rounded="lg" width="100%" class="pa-4 text-center mx-auto">
      <v-icon class="mb-5" color="blue" icon="mdi-sale" size="112"></v-icon>

      <h2 class="text-h5 mb-6">{{ $t("crowdfunding.voucher_notfound") }}</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        {{ $t("crowdfunding.voucher_notfound_desc") }}
        <br />
        <a :href="'/'" class="text-decoration-none text-info">
          {{ $t("crowdfunding.home") }}</a>
      </p>
      <v-btn @click="reloadOnclick"> {{ $t("crowdfunding.relaod") }}</v-btn>
    </v-sheet>
  </div>

  <div v-else>
    <v-sheet class="pa-0 ma-0 mx-auto" color="white" max-width="1200" rounded="lg" width="100%" elevation="0">
      <div class="mt-6">
        <v-btn block prepend-icon="mdi-store" color="black" variant="text" style="font-weight: bold" :ripple="false"
          @click="onClickShopDetail" size="small">
          {{ shopName }}
        </v-btn>
      </div>

      <v-row class="ma-0">
        <v-col cols="12">
          <VoucherDiscountCell class="mt-2" :item="userVoucher.crowdfunding_item" :showAllItem="false"
            @voucherCellCallBack="voucherCellCallBack" :showTicketCount="false"></VoucherDiscountCell>
          <div align="center">
            <v-btn class="mt-4" elevation="2" block color="green" height="60px" style="font-weight: bold"
              :disabled="userVoucher && !!userVoucher.used_at" @click="onClickUse">
              {{
                $t(
                  userVoucher && userVoucher.used_at
                    ? "crowdfunding.usedVoucher"
                    : "usevoucher.useOK"
                )
              }}
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-4 mx-2">
        <p class="text-h6 mt-6 pa-2" style="font-weight: bold">
          <v-icon icon="mdi-help-circle-outline" color="green" class="mt-0 mx-2" size="x-small"></v-icon>
          {{ $t("crowdfunding.howTouse") }}
        </p>
        <v-divider></v-divider>
        <div>
          <div v-snip="{ lines: 2 }" style="font-weight: normal" class="ma-2">
            <p class="text-body-1">{{ $t("crowdfunding.voucher_howto_0") }}</p>
          </div>
          <v-btn variant="text" @click="howtoOnClick()">
            {{ $t("homeview.show_more") }}
          </v-btn>
        </div>
      </div>

      <div class="mt-4 mx-2">
        <p class="text-h6 mt-6 pa-2" style="font-weight: bold">
          <v-icon icon="mdi-alert-circle-outline" color="red" class="mt-0 mx-2" size="x-small"></v-icon>
          {{ $t("crowdfunding.terms_policy") }}
        </p>
        <v-divider></v-divider>
        <div>
          <div v-snip="{ lines: 2 }" style="font-weight: normal" class="ma-2">
            <p class="text-body-1">{{ $t("crowdfunding.voucher_term_1") }}</p>
          </div>
          <v-btn variant="text" @click="termOnClick()">
            {{ $t("homeview.show_more") }}
          </v-btn>
        </div>
      </div>
    </v-sheet>

    <v-row justify="center">
      <v-dialog v-model="useVoucherDialog" persistent width="auto">
        <v-card prepend-icon="mdi-alert-circle-outline">
          <template v-slot:title>
            {{ $t("usevoucher.plsRead") }}
          </template>

          <v-card-text>
            <p class="text-body-1">{{ $t("usevoucher.useTitle") }}</p>
            <p class="text-body-1">{{ $t("usevoucher.useDesc") }}</p>
            <p class="text-body-2 text-red mt-4">
              {{ $t("usevoucher.useDesc2") }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="text" @click="useVoucherDialog = false">
              {{ $t("usevoucher.cancel") }}
            </v-btn>
            <v-btn color="green-darken-1" variant="outlined" @click="onClickUseVoucherOK">
              {{ $t("usevoucher.useOK") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular :indeterminate="loadingDialog" :size="100" color="blue" class="mt-12">
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>

  <v-bottom-sheet v-model="bottomSheet" inset>
    <v-card :title="bottomSheetTitle">
      <v-card-text>
        <span style="white-space: pre-line" class="text-body-2">
          {{ bottomSheetDesc }}
        </span>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import VoucherDiscountCell from "@/components/VoucherDiscountCell.vue";
import { VSonner, toast } from "vuetify-sonner";
import { mapGetters } from "vuex";

export default {
  components: {
    VoucherDiscountCell,
  },

  props: ["crowdfundingItemId", "shopId"],

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
    }),
  },

  watch: {
    isAuthenticated(to, from) { },
  },

  data() {
    return {
      loadingDialog: true,
      useVoucherDialog: false,
      userVoucher: {},
      shopName: null,
      bottomSheet: false,
      bottomSheetTitle: null,
      bottomSheetDesc: null,
      voucherNotFound: false,
    };
  },

  async mounted() {
    window.scrollTo(0, 0);
    if (!this.crowdfundingItemId) {
      this.backToHome("mounted");
    }
    await this.getUserCorwdfundingItemData();
    this.loadingDialog = false;
  },

  methods: {
    onClickUse() {
      this.useVoucherDialog = true;
    },
    async getUserCorwdfundingItemData() {
      await this.$store
        .dispatch("userStore/getUserVoucher", this.crowdfundingItemId)
        .then((response) => {
          if (response) {
            this.userVoucher = response;
            if (
              this.userVoucher &&
              this.userVoucher.crowdfunding_item &&
              this.userVoucher.crowdfunding_item.crowdfunding &&
              this.userVoucher.crowdfunding_item.crowdfunding.shop
            ) {
              this.shopName =
                this.userVoucher.crowdfunding_item.crowdfunding.shop.name;
            }
          } else {
            this.backToHome("response");
          }
        })
        .catch((error) => {
          console.error(error);
          this.backToHome("error");
        });
    },

    async onClickUseVoucherOK() {
      this.useVoucherDialog = false;
      this.loadingDialog = true;
      const tobeSubmit = {
        crowdfunding_item_id: this.userVoucher.crowdfunding_item_id,
      };
      await this.$store
        .dispatch("userStore/useVoucher", tobeSubmit)
        .then((response) => {
          if (response) {
            this.userVoucher = response;
          } else {
            alert("Error something occurs.");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },
    howtoOnClick() {
      this.bottomSheet = !this.bottomSheet;
      this.bottomSheetTitle = this.$i18n.t("crowdfunding.howTouse");

      let texts = [
        this.$i18n.t("crowdfunding.voucher_howto_0"),
        this.$i18n.t("crowdfunding.voucher_howto_1"),
        this.$i18n.t("crowdfunding.voucher_howto_2"),
        this.$i18n.t("crowdfunding.voucher_howto_3"),
      ];

      this.bottomSheetDesc = texts.join("\n\n");
    },

    termOnClick() {
      this.bottomSheet = !this.bottomSheet;
      this.bottomSheetTitle = this.$i18n.t("crowdfunding.terms_policy");

      let texts = [
        this.$i18n.t("crowdfunding.voucher_term_0"),
        this.$i18n.t("crowdfunding.voucher_term_1"),
        this.$i18n.t("crowdfunding.voucher_term_2"),
        this.$i18n.t("crowdfunding.voucher_term_3"),
        this.$i18n.t("crowdfunding.voucher_term_4"),
      ];

      this.bottomSheetDesc = texts.join("\n\n");
    },

    reloadOnclick() {
      location.reload();
    },

    onClickShopDetail() {
      if (
        this.userVoucher &&
        this.userVoucher.crowdfunding_item &&
        this.userVoucher.crowdfunding_item.crowdfunding &&
        this.userVoucher.crowdfunding_item.crowdfunding.shop
      ) {
        this.$router.push({
          name: "ShopBySlugV2",
          params: { shop_slug: this.userVoucher.crowdfunding_item.crowdfunding.shop.shop_slug },
        })
      } else {
        this.$router.push({
          name: "Shop",
          params: { id: this.shopId },
        })
      }
    },

    async backToHome(value) {
      console.log("backToHome " + value);
      this.voucherNotFound = true;
    },
  },
};
</script>

<style scoped></style>