<template>
  <v-sheet
    v-if="shopData.name != null"
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-container fluid>

      <v-row>
        <v-col>
          <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
          <h1 class="text-body-1" style="font-weight: bold">
            <v-icon
              v-if="shopData.is_official"
              icon="mdi-check-decagram"
              size="16"
              color="blue"
              class="mt-n1"
            />
            {{ shopData.name }}
          </h1>

          <v-icon icon="mdi-map-marker" size="14" color="grey" class="mx-0" />
          <span
            class="mx-2 text-black"
            style="font-size: 12px; font-weight: normal"
          >
            {{ getShopPrefectureTitle(shopData) }}
          </span>
        </v-col>

        <v-spacer></v-spacer>

        <v-btn
          icon="mdi-heart"
          color="blue"
          elevation="0"
          :ripple="false"
          size="x-small"
          :variant="this.isLikedFavorite ? 'tonal' : 'outlined'"
          @click="favoriteOnClick"
        ></v-btn>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="7">
          <div class="mx-0">
            <v-icon
              icon="mdi-briefcase"
              color="black"
              size="x-small"
              class="mx-0"
            />
            <span
              style="font-size: 12px; font-weight: normal"
              class="mx-2 text-black"
            >
              {{ this.todayWorkHours }}
            </span>
          </div>
        </v-col>

        <v-col cols="5">
          <div class="mx-0">
            <v-icon icon="mdi-cash" color="black" size="x-small" class="mx-0" />
            <span style="font-size: 12px" class="mx-2 text-black">
              {{ shopPriceRange }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="7">
          <div class="mx-0">
            <v-icon icon="mdi-star" color="black" size="16" class="mx-0" />
            <span
              style="font-size: 12px"
              class="mx-2 text-black"
              v-if="shopDetail.os_rating"
            >
              {{ shopDetail.os_rating }} ({{ shopDetail.os_reviews }})
            </span>
            <span
              v-else
              style="font-size: 12px; font-weight: normal"
              class="mx-2 text-black"
            >
              {{ $t("searchresults.no_review") }}
            </span>
          </div>
        </v-col>

        <v-col cols="5">
          <div class="mx-0">
            <v-icon icon="mdi-heart" color="black" size="16" class="mx-0" />
            <span style="font-size: 12px" class="mx-2 text-black">
              {{ this.shopData.favorite_count }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!--ImageBanner -->
    <v-layout class="ma-0 mt-4" v-if="shopPictures.length > 0">
      <v-row no-gutter>
        <v-col class="mt-n2">
          <v-carousel
            :height="this.isMobileDevice.value ? 280 : 500"
            width="100%"
            hide-delimiters
            color="grey-darken-1"
            show-arrows="hover"
          >
            <v-carousel-item
              v-for="picture in shopPictures"
              :key="picture"
              eager
              transition="fade-transition"
            >
              <v-img
                cover
                :src="picture.big"
                :lazy-src="local_logo"
                height="100%"
                @click="showMoreImageOnClick()"
              >
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <div
        class="ribbon ribbon-top-right"
        v-if="crowdfundings !== undefined && crowdfundings.length > 0"
      >
        <span class="text-body-1">{{ $t("homeview.voucher") }}</span>
      </div>
    </v-layout>

    <div align="right" class="mt-0">
      <v-btn-toggle
        class="ma-2"
        v-model="photo_position"
        rounded="lg"
        color="blue"
        group
        divided
        variant="outlined"
      >
        <v-btn
          elevation="0"
          size="small"
          :ripple="false"
          prepend-icon="mdi-image"
          @click.prevent="showMoreImageOnClick()"
        >
          + {{ totalPhotoCount }}
        </v-btn>
        <v-btn
          icon="mdi-tray-arrow-up"
          :ripple="false"
          @click="snsShare"
          elevation="0"
          size="small"
        >
        </v-btn>
      </v-btn-toggle>
    </div>

    <!--AIReview Icon-->
    <v-list-item class="mt-6">
      <template v-slot:prepend>
        <v-img :src="bot_logo_src" :width="30" />
      </template>
      <p class="text-h6 mx-2 mt-1" style="font-weight: bold">
        {{ $t("shop.review") }}
      </p>
    </v-list-item>

    <!--AIReview -->
    <v-sheet class="ma-3" v-if="!checkEmpty(shopDetail.ai_highlight)">
      <div
        class="text-body-1 mt-1"
        style="font-weight: normal"
        v-if="this.$i18n.locale == 'zhCN'"
      >
        <p v-if="!readActivated" style="font-weight: normal">
          {{ this.shopDetail.ai_highlight_zhcn.slice(0, 100) }}...
          <span
            class="mt-4 text-blue"
            v-if="!readActivated"
            @click="activateReadMore"
            style="font-weight: bold"
            >{{ $t("homeview.show_more") }}</span
          >
        </p>
        <ShopAbout
          v-if="readActivated"
          @chipTagOnClickCallback="chipTagOnClickCallback"
          :shopDetail="shopData"
        />
      </div>

      <div
        class="text-body-1 mt-1"
        style="font-weight: normal"
        v-else-if="this.$i18n.locale == 'ms'"
      >
        <p v-if="!readActivated" style="font-weight: normal">
          {{ this.shopDetail.ai_highlight_ms.slice(0, 200) }}...
          <span
            class="mt-4 text-blue"
            v-if="!readActivated"
            @click="activateReadMore"
            style="font-weight: bold"
            >{{ $t("homeview.show_more") }}</span
          >
        </p>
        <ShopAbout
          v-if="readActivated"
          @chipTagOnClickCallback="chipTagOnClickCallback"
          :shopDetail="shopData"
        />
      </div>

      <div class="text-body-1 mt-1" style="font-weight: normal" v-else>
        <p v-if="!readActivated" style="font-weight: normal">
          {{ this.shopDetail.ai_highlight.slice(0, 200) }}...
          <span
            class="mt-4 text-blue"
            v-if="!readActivated"
            @click="activateReadMore"
            style="font-weight: bold"
            >{{ $t("homeview.show_more") }}</span
          >
        </p>
        <ShopAbout
          v-if="readActivated"
          @chipTagOnClickCallback="chipTagOnClickCallback"
          :shopDetail="shopData"
        />
      </div>
    </v-sheet>

    <v-sheet v-else>
      <div class="text-body-1 mt-1 pa-4" style="font-weight: normal">
        {{ $t("shop.noShopDetails") }}
      </div>
    </v-sheet>

    <v-btn
      class="mt-4 ma-2"
      width="95%"
      variant="outlined"
      prepend-icon="mdi-phone"
      size="x-large"
      rounded="lg"
      height="60"
      flat
      color="green"
      @click="callDialog = true"
    >
      <strong>{{ $t("shop.call_reserve") }}</strong>
    </v-btn>

    <!--Promotions -->
    <v-sheet elevation="0" class="ma-0 mt-6" v-if="crowdfundings.length > 0">
      <v-list-item class="mt-4 mb-2">
        <template v-slot:prepend>
          <v-img src="/discounts.png" :width="30" />
        </template>
        <p class="text-h6 mx-2 mt-0" style="font-weight: bold">
          {{ $t("homeview.special_voucher") }}
        </p>
      </v-list-item>
      <div class="ma-4">
        <CrowdfundingsCard
          :crowdfundingsObjects="crowdfunding_items"
          :showAllItem="true"
        />
      </div>
    </v-sheet>

    <v-style>
      <div ref="ShopTabs">
        <v-card elevation="0.3" class="mt-12 ma-3">
          <v-tabs
            v-model="active_tab"
            bg-color="blue"
            color="white"
            center-active
            grow
          >
            <v-tab value="tab-1" :ripple="false"
              ><v-icon start icon="mdi-image-area" />
              <p class="text-subtitle-2">{{ $t("shop.gallery") }}</p>
            </v-tab>

            <!-- <v-tab value="tab-2" :ripple="false">
              <v-icon start icon="mdi-robot" />
              <p class="text-subtitle-2">
                {{ $t("shop.ai_highlights") }}
              </p>
            </v-tab> -->

            <v-tab value="tab-3" :ripple="false">
              <v-icon start icon="mdi-format-list-bulleted" />
              <p class="text-subtitle-2">{{ $t("shop.menu_promo") }}</p>
            </v-tab>

            <v-tab value="tab-4" :ripple="false">
              <v-icon start icon="mdi-trophy-award" />
              <p class="text-subtitle-2">{{ $t("shop.stylist") }}</p>
            </v-tab>
            <!-- <v-tab value="tab-5" :ripple="false">
              <v-icon start icon="mdi-map-marker" />
              <p class="text-subtitle-2">{{ $t("shop.map") }}</p>
            </v-tab> -->
          </v-tabs>

          <v-card-text>
            <v-window v-model="active_tab" :touch="false">
              <v-window-item value="tab-1">
                <ShopGallery :id="shopData.id" />
              </v-window-item>

              <v-window-item value="tab-2">
                <ShopAbout
                  @chipTagOnClickCallback="chipTagOnClickCallback"
                  :shopDetail="shopData"
                />
              </v-window-item>

              <v-window-item value="tab-3">
                <div
                  style="max-width: 1280px; margin: 0 auto"
                  ref="ShopPriceList"
                >
                  <ShopPriceList v-if="shopData.menus" :shopData="shopData" />
                </div>
              </v-window-item>

              <v-window-item value="tab-4">
                <!-- <TopStyleList
                  :showMoreBtn="`no`"
                  :staffs="shopData ? shopData.staffs : []"
                /> -->
                <ShopStaffList :staffs="shopData ? shopData.staffs : []" />
              </v-window-item>

              <!-- <v-window-item value="tab-5">
                <MapOnly :address="shopDetail.address" />
              </v-window-item> -->
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </v-style>

    <ShopMenuList v-if="shopData.detail != null" :shopObjects="shopData" />

    <ShareWithFriend :shopInfoObjects="shopData" />

    <v-list class="mt-4">
      <ReservationGroup class="ma-2" />
    </v-list>

    <!-- NearThisShop -->
    <ShopNearByShop
      class="mt-12"
      v-if="shopNearByShopCoordinates != null"
      :coordinates="shopNearByShopCoordinates"
    />

    <!-- LocationShopList -->
    <v-sheet class="mt-12" v-if="haveCurrentCoordinates != null">
      <LocationShopList />
    </v-sheet>
  </v-sheet>

  <!-- Sponsor Shop -->
  <v-sheet class="mt-6 mb-12">
    <SponsorBanner
      v-if="getsponsoreList && getsponsoreList.length > 0 && timerShow"
      :itemObjects="getsponsoreList"
    />
  </v-sheet>

  <!-- Footer -->
  <v-bottom-navigation :active="bottomBtnActive" grow bg-color="green">
    <v-btn prepend-icon="mdi-phone" :ripple="false" @click="callDialog = true">
      <p style="font-weight: bold">{{ $t("shop.call_reserve") }}</p>
    </v-btn>

    <!--TMP: Only shop1 can online reserve -->
    <!-- to="{ name: 'ReservationForm', params: { id: shopData.id } }" -->
    <v-btn
      v-if="shopData.company_appointment_setting && shopData.id == 1"
      prepend-icon="mdi-calendar-check"
      :ripple="false"
      :to="{ name: 'ReservationFormV2BySlug', params: { shop_slug: shopData.shop_slug } }"
      >
      <p style="font-weight: bold">{{ $t("shop.online_reserve") }}</p>
    </v-btn>

    <v-btn prepend-icon="mdi-tray-arrow-up" :ripple="false" @click="snsShare">
      <p style="font-weight: bold">{{ $t("shop.share") }}</p>
    </v-btn>
  </v-bottom-navigation>

  <v-dialog v-model="callDialog" width="auto">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("shop.before_making") }}
      </v-card-title>
      <v-card-text>
        {{ $t("shop.pls_tell_store") }}
        <span class="text-h6" style="font-weight: bold">Beauty360</span>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="makeACall">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <SNSShareDialog
    v-if="shopData.detail != null"
    :shopInfoObjects="shopData"
    :openSNSShareDialog="openSNSShareDialog"
    @result="responseFromSNSDialog"
  ></SNSShareDialog>

  <VSonner position="top-center" />

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
      >
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>

  <!-- PageNotFound -->
  <NotFoundView v-if="showPageNotFound"></NotFoundView>
</template>

<script>
import { useHead } from "@vueuse/head";
import TopStyleList from "@/components/TopStyleList.vue";
import ShopMenuList from "@/components/ShopMenuList.vue";
import ShareWithFriend from "@/components/ShareWithFriend.vue";
import ReservationGroup from "@/components/ReservationGroup.vue";
import ShopGallery from "@/components/ShopGallery.vue";
import ShopAbout from "@/components/ShopAbout.vue";
import ShopPriceList from "@/components/ShopPriceList.vue";
import MapOnly from "@/components/MapOnly.vue";
import ShopStaffList from "@/components/ShopStaffList.vue";
import CuponSheet from "@/components/CuponSheet.vue";
import SNSShareDialog from "@/components/SNSShareDialog.vue";
import NotFoundView from "./NotFoundView.vue";
import Constants from "@/uitls/Constants";
import { VSonner, toast } from "vuetify-sonner";
import { mapState, mapActions, mapGetters } from "vuex";
import Carousel from "@/uitls/Carousel.vue";
import CrowdfundingsCard from "@/components/CrowdfundingsCard.vue";
import utils from "@/uitls/Uitls.js";
import LocationShopList from "@/components/LocationShopList.vue";
import ShopNearByShop from "@/components/ShopNearByShop.vue";
import SponsorBanner from "@/components/Banner/SponsorBanner.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  props: ["shop_slug"],

  components: {
    TopStyleList,
    ShopMenuList,
    ShareWithFriend,
    ReservationGroup,
    ShopGallery,
    ShopAbout,
    ShopPriceList,
    MapOnly,
    ShopStaffList,
    CuponSheet,
    SNSShareDialog,
    VSonner,
    Carousel,
    CrowdfundingsCard,
    LocationShopList,
    ShopNearByShop,
    SponsorBanner,
    NotFoundView,
    Breadcrumbs,
  },

  setup() {},

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      item: "authStore/item",
      userInfo: "userStore/userInfo",
    }),
    ...mapState({
      getsponsoreList: (state) => state.SponsorStore.sponsoreList,
    }),
  },

  watch: {
    isAuthenticated(to, from) {},
    $route(to, from) {
      this.resetValue();
      this.parseShopV2();
    },
  },

  data() {
    return {
      loadingDialog: true,
      readActivated: false,
      shopData: {
        shop_slug: this.shop_slug,
      },
      shopDetail: {
        station: null,
        phone: null,
      },

      shopPictures: [],
      crowdfundings: [],
      crowdfunding_items: [],
      favorites: [],
      page: 1,
      perPage: 10,
      isLikedFavorite: false,
      showMoreHighLight: false,
      local_logo: require("@/assets/img/logo120.png"),
      bot_logo_src: require("@/assets/img/robot_120.png"),
      callDialog: false,
      bottomBtnActive: false,
      totalPhotoCount: 0,
      active_tab: "tab-1",
      shortcutList: ["Hair Cut", "Color", "Prem"],
      openSNSShareDialog: false,
      haveCurrentCoordinates: null,
      itemCupon: {
        title: "Cut + Color",
        eligible: "New customer only",
        desc: "Leekaja Beauty Salon is the best Korean hair salon in Malaysia",
        price: " RM100",
      },
      shopNearByShopCoordinates: null,
      todayWorkHours: null,

      businessHours: null,
      shopPriceRange: "RM-",
      timerShow: false,
      showPageNotFound: false,

      breadCrumbsProps: [],
    };
  },

  async created() {},

  async beforeMount() {
    await this.parseShopV2();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getScrollPosition();
  },

  methods: {
    checkEmpty(obj) {
      return utils.isEmpty(obj) ? true : false;
    },

    activateReadMore() {
      this.readActivated = true;
    },

    resetValue() {
      (this.shopData = {
        shop_slug: this.shop_slug,
      }),
        (this.shopDetail = {
          station: null,
          phone: null,
        }),
        (this.shopPictures = []),
        (this.crowdfundings = []),
        (this.crowdfunding_items = []),
        (this.favorites = []),
        (this.isLikedFavorite = false),
        (this.showMoreHighLight = false),
        (this.totalPhotoCount = 0),
        (this.active_tab = "tab-1"),
        (this.shortcutList = ["Hair Cut", "Color", "Prem"]),
        (this.openSNSShareDialog = false),
        (this.haveCurrentCoordinates = null),
        (this.readActivated = false),
        (this.shopNearByShopCoordinates = null);
    },

    async parseShopV2() {
      await this.$store
        .dispatch("shopStore/fetchShopV2", this.shop_slug)
        .then(async (res) => {
          if (res) {
            this.shopData = res;
            this.showPageNotFound = false;

            if (this.shopData.detail) {
              this.shopDetail = this.shopData.detail;
            }
            if (this.shopData.crowdfundings) {
              this.crowdfundings = this.shopData.crowdfundings;
              if (this.crowdfundings.length > 0) {
                await this.fetchCrowdfunding(this.crowdfundings[0].id);
              }
            }
            if (this.shopData.pictures) {
              this.totalPhotoCount = this.shopData.pictures.length;
              if (this.shopData.pictures.length > 0) {
                this.shopData.pictures.forEach((v, i) => {
                  this.shopPictures.push({
                    id: i,
                    big: v.picture_url,
                    thumb: v.picture_url_small,
                  });
                });
              }
            }
            if (this.shopData.schedule_policy) {
              this.businessHours = this.shopData.schedule_policy;
              this.handleShopBusinessHour();
            }
            this.shopPriceRange = utils.calculateShopPriceRange(this.shopData);

            this.handleBreadcrumbs()
          } else {
            this.shopData = {};
            this.showPageNotFound = true;
          }
        })
        .catch((e) => {
          console.error(e);
          this.$router.push("/404");
        })
        .finally(() => {
          this.loadingDialog = false;
        });

      this.haveCurrentCoordinates = this.$cookies.get("coordinates");

      if (this.isAuthenticated) {
        this.fetchMypageFavorites();
      }

      this.shopNearByShopCoordinates = {
        shop_id: this.shopData.id,
        latitude: this.shopData.latitude,
        longitude: this.shopData.longitude,
      };

      this.setMetaInfo();

      setTimeout(() => (this.timerShow = true), 500);
    },

    setMetaInfo() {
      useHead({
        title: this.shopData.name + " | " + this.$i18n.t("homeview.find_your_salon") + "Beauty 360",
        meta: [
          {
            name: "description",
            content: this.shopDetail ? this.shopDetail.ai_review_short : "",
          },
          {
            name: "keywords",
            content: utils.removeArraySymbleForKeyword(
              this.shopData.tags.toString()
            ),
          },
          {
            property: "og:title",
            content: this.shopData.name + " | " + this.$i18n.t("homeview.find_your_salon") + "Beauty 360",
          },
          {
            property: "og:description",
            content: this.shopDetail ? this.shopDetail.ai_review_short : "",
          },
          {
            property: "og:url",
            content: "https://beauty-360.com/" + this.shopData.shop_slug,
          },
          {
            property: "og:image",
            content: this.shopData.pictures && this.shopData.pictures.length > 0 ? this.shopData.pictures[0].picture_url : "",
          },
          {
            property: "og:image:alt",
            content: this.shopData.name + " | Beauty 360",
          },
        ],
      });
    },

    showMoreImageOnClick() {
      this.active_tab = "tab-1";
      this.$refs.ShopTabs.scrollIntoView({ behavior: "smooth" });
    },
    showMoreVoucherOnClick() {
      this.$refs.ShopPriceList.scrollIntoView({ behavior: "smooth" });
    },

    showMoreShopDetailOnClick() {
      this.active_tab = "tab-2";
      this.$refs.ShopTabs.scrollIntoView({ behavior: "smooth" });
    },

    getScrollPosition() {
      window.onscroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        let bottomOfWindow = scrollPosition >= document.body.offsetHeight - 50;
        if (bottomOfWindow) {
          this.bottomBtnActive = false;
        } else if (scrollPosition < 1000) {
          this.bottomBtnActive = false;
        } else {
          this.bottomBtnActive = true;
        }
      };
    },

    async fetchCrowdfunding(crowdId) {
      await this.$store
        .dispatch("crowdStore/fetchCrowd", crowdId)
        .then((response) => {
          this.crowdfunding_items = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async fetchMypageFavorites() {
      const params = {
        id: this.userInfo.id,
        target_type: Constants.FAVORITE_TYPE_SHOP,
        page: 1,
        per_page: 300,
      };

      await this.$store
        .dispatch("userStore/fetchFavorites", params)
        .then((response) => {
          this.favorites = response.data;
          this.chceckHasFavorite();
        })
        .catch((error) => {});
    },

    chceckHasFavorite() {
      var tmp = this.favorites.filter((e) => e.target_id == this.shopData.id);
      if (tmp.length > 0) {
        this.isLikedFavorite = true;
      } else {
        this.isLikedFavorite = false;
      }
    },

    favoriteOnClick() {
      if (!this.isAuthenticated) {
        toast(this.$i18n.t("shop.pls_login"), {
          cardProps: {
            color: "info",
            class: "my-toast",
          },
        });
        return;
      }

      const params = {
        target_type: Constants.FAVORITE_TYPE_SHOP,
        target_id: this.shopData.id,
        user_id: this.item.user.id,
      };

      this.isLikedFavorite = !this.isLikedFavorite;
      if (this.isLikedFavorite) {
        this.addFavorite(params);
      } else {
        this.deleteFavorite(params);
      }
    },

    addFavorite(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("userStore/addFavorite", params)
          .then((response) => {
            resolve(response);
            toast(this.$i18n.t("shop.fav_added"), {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteFavorite(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("userStore/deleteFavorite", params)
          .then((response) => {
            resolve(response);
            toast(this.$i18n.t("shop.fav_remove"), {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    chipTagOnClickCallback(value) {
      const searchFilterObjects = {
        selectedTag: [value],
      };

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },

    makeACall() {
      this.callDialog = false;
      window.open("tel:" + this.shopDetail.phone, "_self");
    },

    snsShare() {
      this.openSNSShareDialog = true;
    },

    responseFromSNSDialog() {
      this.openSNSShareDialog = false;
    },

    getShopPrefectureTitle(value) {
      const prefectureTitle = this.$i18n.t(utils.getShopPrefectureTitle(value));

      if (value.city && value.city.code) {
        const cityTitle = this.$i18n.t(`city.${value.city.code}`);
        return `${prefectureTitle}, ${cityTitle}`;
      }
      return prefectureTitle;
    },

    handleShopBusinessHour() {
      if (this.businessHours) {
        const d = new Date();
        switch (d.getDay()) {
          case 1:
            this.todayWorkHours = this.$i18n.t("shop.mon");
            if (utils.isEmpty(this.businessHours.opening_time_1)) {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                this.$i18n.t("shop.closed")
              );
            } else {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                utils.formatAMPM(
                  new Date(
                    Date.parse(
                      "Thu, 01 Jan 1970 " + this.businessHours.opening_time_1
                    )
                  )
                ) +
                  " ~ " +
                  utils.formatAMPM(
                    new Date(
                      Date.parse(
                        "Thu, 01 Jan 1970 " + this.businessHours.closing_time_1
                      )
                    )
                  )
              );
            }
            break;
          case 2:
            this.todayWorkHours = this.$i18n.t("shop.tue");
            if (utils.isEmpty(this.businessHours.opening_time_2)) {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                this.$i18n.t("shop.closed")
              );
            } else {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                utils.formatAMPM(
                  new Date(
                    Date.parse(
                      "Thu, 01 Jan 1970 " + this.businessHours.opening_time_2
                    )
                  )
                ) +
                  " ~ " +
                  utils.formatAMPM(
                    new Date(
                      Date.parse(
                        "Thu, 01 Jan 1970 " + this.businessHours.closing_time_2
                      )
                    )
                  )
              );
            }
            break;
          case 3:
            this.todayWorkHours = this.$i18n.t("shop.wed");
            if (utils.isEmpty(this.businessHours.opening_time_3)) {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                this.$i18n.t("shop.closed")
              );
            } else {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                utils.formatAMPM(
                  new Date(
                    Date.parse(
                      "Thu, 01 Jan 1970 " + this.businessHours.opening_time_3
                    )
                  )
                ) +
                  " ~ " +
                  utils.formatAMPM(
                    new Date(
                      Date.parse(
                        "Thu, 01 Jan 1970 " + this.businessHours.closing_time_3
                      )
                    )
                  )
              );
            }
            break;
          case 4:
            this.todayWorkHours = this.$i18n.t("shop.thu");
            if (utils.isEmpty(this.businessHours.opening_time_4)) {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                this.$i18n.t("shop.closed")
              );
            } else {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                utils.formatAMPM(
                  new Date(
                    Date.parse(
                      "Thu, 01 Jan 1970 " + this.businessHours.opening_time_4
                    )
                  )
                ) +
                  " ~ " +
                  utils.formatAMPM(
                    new Date(
                      Date.parse(
                        "Thu, 01 Jan 1970 " + this.businessHours.closing_time_4
                      )
                    )
                  )
              );
            }
            break;
          case 5:
            this.todayWorkHours = this.$i18n.t("shop.fri");
            if (utils.isEmpty(this.businessHours.opening_time_5)) {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                this.$i18n.t("shop.closed")
              );
            } else {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                utils.formatAMPM(
                  new Date(
                    Date.parse(
                      "Thu, 01 Jan 1970 " + this.businessHours.opening_time_5
                    )
                  )
                ) +
                  " ~ " +
                  utils.formatAMPM(
                    new Date(
                      Date.parse(
                        "Thu, 01 Jan 1970 " + this.businessHours.closing_time_5
                      )
                    )
                  )
              );
            }
            break;
          case 6:
            this.todayWorkHours = this.$i18n.t("shop.sat");
            if (utils.isEmpty(this.businessHours.opening_time_6)) {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                this.$i18n.t("shop.closed")
              );
            } else {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                utils.formatAMPM(
                  new Date(
                    Date.parse(
                      "Thu, 01 Jan 1970 " + this.businessHours.opening_time_6
                    )
                  )
                ) +
                  " ~ " +
                  utils.formatAMPM(
                    new Date(
                      Date.parse(
                        "Thu, 01 Jan 1970 " + this.businessHours.closing_time_6
                      )
                    )
                  )
              );
            }
            break;
          case 0:
            this.todayWorkHours = this.$i18n.t("shop.sun");
            if (utils.isEmpty(this.businessHours.opening_time_0)) {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                this.$i18n.t("shop.closed")
              );
            } else {
              this.todayWorkHours = this.todayWorkHours.concat(
                " ",
                utils.formatAMPM(
                  new Date(
                    Date.parse(
                      "Thu, 01 Jan 1970 " + this.businessHours.opening_time_0
                    )
                  )
                ) +
                  " ~ " +
                  utils.formatAMPM(
                    new Date(
                      Date.parse(
                        "Thu, 01 Jan 1970 " + this.businessHours.closing_time_0
                      )
                    )
                  )
              );
            }
            break;
        }
      }
    },

    handleBreadcrumbs() {
      const vm = this
      // console.log('this.shopData', this.shopData);
      // console.log('this.shopDetail', this.shopDetail);
      vm.breadCrumbsProps = {
        data: [
          {
            name: 'mypage.topPage',
            to: '/',
            disabled: false,
          },
        ]
      }

      if (vm.shopData.prefecture_v2) {
        vm.breadCrumbsProps.data.push({
          name: 'prefecture.' + vm.shopData.prefecture_v2.code,
          to: '/hairsalon/' + vm.shopData.prefecture_v2.name,
          disabled: false,
        })
      }
      if (vm.shopData.city) {
        vm.breadCrumbsProps.data.push({
          name: 'city.' + vm.shopData.city.code,
          to: '/hairsalon/' + vm.shopData.prefecture_v2.name + '/' + vm.shopData.city.name,
          disabled: false,
        })
      }

      const allQueries = vm.$route.query
      Object.entries(allQueries).forEach(([key, value]) => {
        switch(key) {
          case 'topic':
            // const targetTopic = vm.shopData.topics.find(item => item.id == value)
            // if (targetTopic) {
            //   vm.breadCrumbsProps.data.push({
            //     name: targetTopic['title_' + this.$i18n.locale],
            //     to: targetTopic['url_' + this.$i18n.locale],
            //     disabled: false,
            //   })
            // }
              vm.breadCrumbsProps.data.push({
                name: value,
                to: '/topic/' + value,
                disabled: false,
              })
            break
          case 'menu':
            const targetMenu = vm.shopData.menus.find(item => item.id == value)
            if (targetMenu) {
              // vm.breadCrumbsProps.data.push({
              //   name: 'city.' + vm.shopData.city.code,
              //   to: '/shop/' + this.shopData.id,
              //   disabled: true,
              // })
            }
            break
        }
      })

      vm.breadCrumbsProps.data.push({
        name: vm.shopData.name,
        disabled: true,
      })

    },
  },
};
</script>

<style scoped>
.tdLeft {
  width: 100%;
  min-width: 200px;
  table-layout: fixed;
  text-align: left;
  /* border: 1px solid black; */
  padding: 0px;
}

.scrollContainer {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
}

.scrollContent {
  display: flex;
  flex-wrap: nowrap;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid red;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  background-color: red;
  color: #fff;
  font: 500 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 22px;
}

.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}

.ribbon-top-right span {
  left: -27px;
  top: 29px;
  transform: rotate(45deg);
}
</style>