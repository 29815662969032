<template>
  <div>
    <!-- Show Title -->
    <span class="text-subtitle-2" style="font-weight: bold">
      {{ showResultByType() }}
    </span>

    <!-- Show City -->
    <!-- <span
      class="text-caption text-grey"
      v-if="item.type == 'city' && item.prefecture_v2_name"
    > , {{ item.prefecture_v2_name }}
    </span> -->

    <!-- Count -->
    <span
      class="text-caption text-grey"
      style="font-weight: normal"
      v-if="
        (item.type == 'prefecture' || item.type == 'city') &&
        item.shops_count &&
        item.shops_count > 0
      "
    >
      ({{ item.shops_count + $t("searchAutocompleteResultDiv.shopCount") }})
    </span>
  </div>

  <!-- Sublayer -->
  <div class="mt-n1 text-caption text-grey">
    <!-- Type: Menu,City,Prefecture -->
    <span style="font-weight: normal">
      {{ $t("searchAutocompleteResultDiv." + item.type) }}
    </span>

    <!-- Sub shopname -->
    <span
      style="font-weight: normal"
      v-if="
        item.type == 'menu' ||
        item.type == 'crowndfunding' ||
        item.type == 'crowndfundingItem'
      "
    >
      : {{ item.shop_name }}
    </span>
  </div>
</template>

<script>
export default {
  name: "SearchAutocompleteResultDiv",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      results: [],
      search: null,
      isLoading: false,
      prevSearch: null,
    };
  },
  methods: {
    showResultByType() {
      let result = null;

      switch (this.item.type) {
        case "shop":
        case "menu":
        case "crowndfunding":
        case "crowndfundingItem":
          result = this.item.name;
          break;
        case "prefecture":
          result = this.$i18n.t("prefecture." + this.item.prefecture_code);
          break;
        case "city":
          result = this.$i18n.t("city." + this.item.city_code);
          break;
      }

      return result;
    },
  },
};
</script>

<style>
</style>