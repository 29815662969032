<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    max-width="1200"
    width="100%"
    elevation="0"
  >
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-heart"
        color="pink-darken-1"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t("homeview.our_sponsore") }}
    </p>

    <v-container fluid>
      <v-row class="pa-2" justify="center">
        <v-col v-for="item in itemObjects" :key="item" cols="6" md="2">
          <v-card>
            <v-img
              :src="item.banner_img"
              :lazy-src="local_logo"
              aspect-ratio="1"
              class="bg-grey-lighten-2"
              cover
              @click="bannerOnClick(item.banner_url)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-row justify="center" no-gutters>
      <v-sheet
        rounded
        color="black"
        elevation="0"
        width="320px"
        class="mt-4 white--text pa-4"
        @click="gotoBusinessPage()"
      >
        <div class="d-flex align-center">
          <v-img :src="rate_logo" width="50px" height="50px"></v-img>
          <p
            class="mx-4 text-body-2"
            style="font-weight: normal"
            v-snip="{ lines: 4 }"
          >
            {{ $t("busienssPage.postConsider") }}
          </p>
        </div>
      </v-sheet>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    itemObjects: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    // console.log(props.itemObjects);
  },

  data() {
    return {
      rate_logo: require("@/assets/img/rate.png"),
    };
  },

  methods: {
    bannerOnClick(url) {
      window.open(url, "_self");
    },

    gotoBusinessPage() {
      this.$router.push({
        name: "BusinessPage",
      });
    },
  },
};
</script>

<style></style>