<template>
  <v-toolbar flat app color="gray" :height="isMobileDevice.value ? 48 : 52">
    <v-app-bar
      app
      flat
      :height="isMobileDevice.value ? 48 : 52"
      color="grey-lighten-5"
    >
      <v-toolbar-title>
        <v-img
          alt="Beauty360 "
          v-bind:src="logo_src"
          height="30"
          @click="logoClick"
          aspect-ratio="1"
        ></v-img
      ></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        v-if="isAuthenticated"
        class="text-none"
        variant="outlined"
        rounded="xl"
        size="small"
        prepend-icon="mdi-account-circle"
        color="black"
        :ripple="false"
        :to="{ name: 'MyPage' }"
      >
        <template v-slot:prepend>
          <v-icon color="blue"></v-icon>
        </template>
        <p style="font-weight: normal">{{ $t("mypage.mypage") }}</p>
      </v-btn>

      <v-btn
        v-else
        class="text-none"
        variant="outlined"
        rounded="xl"
        size="small"
        prepend-icon="mdi-account-circle"
        color="black"
        :ripple="false"
        :to="{ name: 'LoginPage' }"
      >
        <p style="font-weight: normal">{{ $t("loginpage.become_member") }}</p>
      </v-btn>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-translate" v-bind="props" :ripple="false"></v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="lang in languages"
            :key="lang.title"
            @click="changeLanguage(lang.language)"
          >
            <v-list-item-title>{{ lang.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-app-bar-nav-icon
        class="text-black"
        @click="drawer = !drawer"
        :ripple="false"
      />
    </v-app-bar>
  </v-toolbar>

  <v-navigation-drawer
    app
    :width="300"
    image="https://guardianangel.network/theme/img/hero-bg.png"
    v-model="drawer"
    location="right"
    @transitionend="navigationHandler"
  >
    <!-- Notification snackbar -->
    <v-layout>
      <Popup @ProjectAdded="snackbar = true" />
    </v-layout>

    <v-layout class="mx-6">
      <!-- <flag iso="us" v-bind:squared="false" /> -->
      <v-spacer></v-spacer>
    </v-layout>

    <template v-slot:prepend>
      <v-sheet v-if="isAuthenticated" class="mt-4">
        <router-link
          :to="{ name: 'MyPage' }"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item
            lines="two"
            :prepend-avatar="avatarImage ? avatarImage : local_avatar"
            :title="userDisplayName"
            :subtitle="userEmail"
          >
          </v-list-item>
        </router-link>
      </v-sheet>

      <v-sheet class="ma-4" height="120" v-else>
        <v-btn
          block
          prepend-icon="mdi-account-circle"
          class="text-none"
          :ripple="false"
          color="blue"
          rounded="lg"
          height="100%"
          elevation="0"
          :to="{ name: 'LoginPage' }"
          ><p class="text-body-1" style="font-weight: bold">
            {{ $t("loginpage.sign_become") }}
          </p></v-btn
        >
      </v-sheet>
    </template>

    <v-sheet>
      <v-icon icon="mdi-translate" color="green" class="mx-8"></v-icon>
      <select v-model="$i18n.locale" class="ma-6 mx-n1">
        <option
          v-for="lang in languages"
          :key="lang.title"
          :value="lang.language"
        >
          <span class="text-body-2">{{ lang.title }}</span>
        </option>
      </select>
    </v-sheet>

    <v-divider></v-divider>

    <v-list class="pa-0" color="blue">
      <v-list-item
        v-for="link in links"
        :key="link.text"
        router
        :ripple="false"
        :to="link.route"
      >
        <v-list-item
          color="blue"
          :prepend-icon="link.icon"
          :title="$t(link.text)"
        ></v-list-item>
      </v-list-item>
    </v-list>

    <template v-slot:append v-if="isAuthenticated">
      <div class="pa-4">
        <v-btn
          block
          elevation="0"
          color="pink"
          height="50px"
          :ripple="false"
          :loading="loading"
          @click="signOut"
        >
          {{ $t("mypage.logout") }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>


<script>
// import Popup from './Popup'
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import { mapState, mapActions, mapGetters } from "vuex";
import utils from "@/uitls/Uitls";

export default {
  props: ["showBackBtn"],

  components: {
    // VueBottomSheet,
    // Popup ,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
    }),
  },

  watch: {
    isAuthenticated(to, from) {},
    user(to, from) {},
  },

  data() {
    return {
      userDisplayName: "",
      userEmail: "",
      loading: false,
      drawer: false,
      local_avatar: require("@/assets/img/avatar.png"),
      avatarImage: null,
      logo_src: require("@/assets/newlogo.png"),
      links: [
        { icon: "mdi-home-outline", text: "mypage.topPage", route: "/" },
        {
          icon: "mdi-store-search-outline",
          text: "mypage.search",
          route: "/hairsalon/searchresult",
        },
        {
          icon: "mdi-newspaper",
          text: "homeview.new_topics",
          route: "/topic",
        },
        {
          icon: "mdi-map",
          text: "homeview.map",
          route: "/map",
        },
        {
          icon: "mdi-sale",
          text: "homeview.voucher",
          route: "/searchvoucher",
        },
      ],

      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "cn", language: "zhCN", title: "中文" },
        { flag: "ms", language: "ms", title: "Melayu" },
      ],
      snackbar: false,
    };
  },

  beforeMount() {
    this.avatarImage = this.local_avatar;
  },

  methods: {
    navigationHandler() {
      this.userEmail = "";
      this.userDisplayName = "";
      if (!utils.isEmpty(this.userInfo)) {
        this.userEmail = this.userInfo.email;
        this.userDisplayName = this.userInfo.name;
        if (!utils.isEmpty(this.userInfo.profile)) {
          this.avatarImage = this.userInfo.profile.picture_url_small;
        } else {
          this.avatarImage = this.local_avatar;
        }
      }
    },

    redirectOnClick() {
      this.$router.push({ name: "HomeView" });
    },

    async signOut() {
      this.loading = true;
      await this.$store
        .dispatch("authStore/signOut")
        .then(() => {
          this.$store.commit("userStore/SET_USER_INFO", {});
          this.$store.dispatch("authStore/setIdToken", null);

          this.$cookies.remove("userToken");
          this.$cookies.remove("userName");
          this.$cookies.remove("userEmail");
          this.$cookies.remove("userPhone");
          this.$cookies.remove("userId");

          this.loading = false;
          this.$router.push("/");
        })
        .catch(() => {
          this.$store.commit("userStore/SET_USER_INFO", {});
          this.$store.dispatch("authStore/setIdToken", null);

          this.$cookies.remove("userToken");
          this.$cookies.remove("userName");
          this.$cookies.remove("userEmail");
          this.$cookies.remove("userPhone");
          this.$cookies.remove("userId");

          this.loading = false;
          this.$router.push("/");
        });
    },

    logoClick() {
      this.$router.push({ name: "HomeView" });
    },

    changeLanguage(newLang) {
      this.$i18n.locale = newLang;
      window.location.reload();
    },
  },
};
</script>

<style>
.others {
  color: white;
}
</style>