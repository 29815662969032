<template>
  <v-sheet class="pa-0 ma-0" color="white">
    <div v-if="shopData.menus.length >= 1">
      <v-expansion-panels multiple class="mt-2">
        <v-expansion-panel
          elevation="0"
          v-for="(value, key) in menu_dict_type"
          v-bind:key="key"
        >
          <v-expansion-panel-title
            class="select-none"
            expand-icon="mdi-menu-down"
          >
            <p class="text-body-1 mx-n4" style="font-weight: normal">
              {{ checkTitle(key) }}
            </p>
          </v-expansion-panel-title>

          <v-expansion-panel-text class="mx-0">
            <v-row no-gutters center warp class="d-flex">
              <v-col cols="12" xs="12" sm="12" md="12">
                <v-sheet>
                  <v-simple-table>
                    <tbody class="mx-0">
                      <tr v-for="(item, index) in value" v-bind:key="index">
                        <td class="tdLeft">
                          <p class="text-body-2 mx-n3">
                            {{ item.title }}
                          </p>
                        </td>
                        <td>
                          <div v-if="item.amount_max > 0">
                            <p
                              v-if="item.amount_max == item.amount"
                              class="text-body-2 text-black tdRight"
                            >
                              RM{{ item.amount }} ~
                            </p>
                            <p v-else class="text-body-2 text-black tdRight">
                              RM{{ item.amount }} ~ RM{{ item.amount_max }}
                            </p>
                          </div>
                          <div v-else>
                            <p class="text-body-2 text-black tdRight">
                              RM{{ item.amount }}
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-sheet>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-container v-else>
      <p style="font-weight: normal" class="text-grey text-caption">{{ $t("shop.noShopDetails") }}</p>
    </v-container>
  </v-sheet>
</template>

<script>
import CuponSheet from "@/components/CuponSheet.vue";
import CrowdfundingsCard from "@/components/CrowdfundingsCard.vue";

export default {
  props: {
    shopData: {
      type: Object,
      default: () => ({}),
    },
    showMinimum: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  components: { CuponSheet, CrowdfundingsCard },

  setup(props) {
    console.log(props.showMinimum);
  },

  computed: {},

  data() {
    return {
      menu_dict_type: {},
    };
  },

  mounted() {
    this.seperateMenuDictType();
  },

  methods: {
    seperateMenuDictType() {
      for (let index = 0; index < this.shopData.menus.length; index++) {
        const element = this.shopData.menus[index];

        if (this.showMinimum && Object.keys(this.menu_dict_type).length > 1) {
          break;
        }

        if (this.menu_dict_type.hasOwnProperty(element.menu_type)) {
          this.menu_dict_type[element.menu_type].push(element);
        } else {
          this.menu_dict_type[element.menu_type] = [element];
        }
      }
    },

    checkTitle(value) {
      let text = value;
      switch (value) {
        case "COLOR":
          text = this.$i18n.t("shopprice.color");
          break;
        case "REBONDING / RELAXING":
          text = this.$i18n.t("shopprice.rebond_relax");
          break;
        case "PERM":
          text = this.$i18n.t("shopprice.prem");
          break;
        case "HAIRCUT":
          text = this.$i18n.t("shopprice.cut");
          break;
        case "CUT":
          text = this.$i18n.t("shopprice.cut");
          break;
        case "HIGHLIGHTS":
          text = this.$i18n.t("shopprice.highligh");
          break;
        case "TREATMENT":
          text = this.$i18n.t("shopprice.treatment");
          break;
        case "OTHERS":
          text = this.$i18n.t("shopprice.other");
          break;
        case "OTHER":
          text = this.$i18n.t("shopprice.other");
          break;
        case "CARE BLEACH":
          text = this.$i18n.t("shopprice.bleach");
          break;
        case "KERATIN":
          text = this.$i18n.t("shopprice.keratin");
          break;
        default:
          text = value;
          break;
      }

      return text;
    },
  },
};
</script>

<style scoped>
tr {
  height: 40px !important;
}

tbody {
  /* background-color: green; */
  display: block;
  width: 100%;
}

.tdLeft {
  width: 100%;
  /* min-width: 150px; */
  table-layout: fixed;
  text-align: left;
  /* border: 1px solid black; */
  padding: 0px;
}

.tdRight {
  table-layout: fixed;
  width: 140px;
  text-align: right;
  padding: 0px;
  /* background: green; */
}

.divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0;
}

.divider::after {
  content: "";
  height: 1.5px;
  background: lightgray;
  flex: 1;
  margin: 0em 0.5em 0em 1em;
}
</style>