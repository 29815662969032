<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-container fluid>
      <v-row class="ma-10">
        <v-col cols="12">
          <div align="center">
            <v-img
              src=" https://docs.lightburnsoftware.com/img/QRCode/ExampleCode.png"
              max-width="320"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <p
            class="text-body-1"
            style="font-weight: bold"
            @click="goToShopDetail()"
          >
            {{ this.shopData.name }}
          </p>
          <p class="text-caption">{{ salesorderDetail.description }}</p>
          <p class="text-caption">{{ salesorderDetail.code }}</p>
        </v-col>
      </v-row>

      <v-card class="mt-0" elevation="0" theme="light" color="grey-lighten-4">
        <v-card-title class="mt-0">
          <v-icon
            icon="mdi-check-circle"
            size="x-small"
            color="green"
            class="mt-n1"
          ></v-icon>
          {{ $t("crowdfunding.after_purchase") }}</v-card-title
        >
        <v-card-text>
          <div class="text-caption">
            {{ $t("crowdfunding.after_purchase_desc") }}
          </div>
        </v-card-text>
      </v-card>

      <v-expansion-panels class="pa-0 mt-4">
        <v-expansion-panel elevation="0" bg-color="blue">
          <v-expansion-panel-title class="text-body-1" style="font-weight: bold"
            ><v-icon
              icon="mdi-basket-outline"
              size="x-small"
              color="white"
              class="mt-0"
            ></v-icon>
            　{{ $t("mypage.purchase_info") }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <v-sheet>
              <v-img
                v-if="salesorderDetail.picture_path"
                :src="salesorderDetail.picture_path"
                class="mb-1"
              ></v-img>

              <p>
                {{ $t("homeview.voucher") }}: {{ salesorderDetail.description }}
              </p>
              <p>{{ $t("mypage.quantity") }}: {{ salesorderDetail.qty }}</p>
              <p>
                {{ $t("mypage.total_amount") }}: RM{{
                  salesorderDetail.totalamount
                }}
              </p>
              <p>
                {{ $t("mypage.purchase_date") }}:
                {{ salesorderDetail.created_at }}
              </p>
            </v-sheet>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel elevation="0" bg-color="blue">
          <v-expansion-panel-title
            class="text-body-1"
            style="font-weight: bold"
          >
            <v-icon
              icon="mdi-alert-circle-outline"
              size="x-small"
              color="white"
              class="mt-0"
            ></v-icon>
            　{{ $t("crowdfunding.fine_print") }}</v-expansion-panel-title
          >
          <v-expansion-panel-text class="text-caption">
            <p>
              Valid at: Damansara Perdana: Mon - Sat: 10.30am - 8pm; Sun and
              public holidays: 10.30am - 7pm. Atria Shopping Gallery: Mon - Sun:
              10am - 10pm.
            </p>
            <br />
            <p>
              Limited to only first-time customers of this saloon. Limit 1
              voucher per person.
            </p>
            <br />
            <p>
              For the Balayage / Ombre Hair Colouring must bleaching /
              pre-lightening the hair, and surcharge applicable for that
              bleaching / pre-lightening For male and female customers.
            </p>
            <br />
            <p>
              Valid for hair length up to bra line. Surcharge of RM50 applicable
              for longer hair. Hair length touched waist surcharge RM100
            </p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel elevation="0" bg-color="blue">
          <v-expansion-panel-title
            class="text-body-1"
            style="font-weight: bold"
          >
            <v-icon
              icon="mdi-alert-circle-outline"
              size="x-small"
              color="white"
              class="mt-0"
            ></v-icon>
            　{{ $t("crowdfunding.cancel_policy") }}</v-expansion-panel-title
          >
          <v-expansion-panel-text class="text-caption">
            {{ $t("crowdfunding.cancel_policy_detail") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <ShopMenuList v-if="shopData.detail != null" :shopObjects="shopData" />
    </v-container>
  </v-sheet>
</template>


<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { VSonner, toast } from "vuetify-sonner";
import utils from "@/uitls/Uitls";
import ShopMenuList from "@/components/ShopMenuList.vue";

export default {
  components: {
    VSonner,
    ShopMenuList,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
      userTrouble: "troubleStore/userTrouble",
    }),
  },

  watch: {
    isAuthenticated(to, from) {
      if (this.isAuthenticated) {
        // this.parseUserInfo();
      }
    },
    user(to, from) {},
  },

  data() {
    return {
      userDisplayName: "test",
      userEmail: "",
      salesorderDetail: {},
      shopData: {
        id: null,
      },
    };
  },

  async beforeMount() {
    this.salesorderDetail = await this.$store.dispatch(
      "CrowdfundingSalesOrderStore/getDetail",
      this.$route.params.id
    );

    if (this.salesorderDetail) {
      this.fetchShopDetail();
    }
  },

  async mounted() {},

  methods: {
    async fetchShopDetail() {
      this.shopData = await this.$store.dispatch(
        "shopStore/fetchShop",
        this.salesorderDetail.shop_id
      );
    },

    goToShopDetail() {
      // this.$router.push({
      //   name: "Shop",
      //   params: { id: this.salesorderDetail.shop_id },
      // })
      this.$router.push({
        name: "ShopBySlugV2",
        params: { shop_slug: this.shopData.shop_slug },
      })
    },
  },
};
</script>