<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn
      class="ma-0"
      icon="mdi-arrow-left"
      @click="$router.push('/bookingpage')"
    ></v-btn>
    <v-app-bar-title>{{ $t("reservationdetails.history") }}</v-app-bar-title>
  </v-app-bar>

  <v-main>
    <v-sheet
      elevation="1"
      max-width="580"
      rounded="lg"
      width="90%"
      class="pa-4 text-left mx-auto mt-6 mb-4"
    >
      <v-img class="shop-picture" :src="shopPic" contain />
      <v-container>
        <!-- ReservationInfo -->
        <v-row class="headerRow mt-6">
          <v-col class="headerCol contentBold px-3 pt-6 pb-1">
            <p style="font-weight: bold">
              {{ $t("reservationdetails.reservation") }}
            </p>
          </v-col>
        </v-row>
        <v-row class="contentRow">
          <v-col cols="12">
            <v-row>
              <v-col class="center contentBold col-4">
                {{ $t("reservationdetails.reserveStatusTitle") }}
              </v-col>
              <v-col class="col-8">
                <v-chip :color="getChipColorClass(reserve.status)">
                  <p class="text-body-1" style="font-weight: bold">
                    {{ getStatusMessage(reserve.status) }}
                  </p>
                </v-chip>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col class="contentBold col-4">
                {{ $t("reservation.reserve_id") }}
              </v-col>
              <v-col class="col-8">
                {{ reserve.id }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="reserve.staff">
            <v-row>
              <v-col class="contentBold col-4">
                {{ $t("reservationdetails.reserveStaffTitle") }}
              </v-col>
              <v-col class="col-8">
                {{ getAppointmentStaffName() }}
              </v-col>
            </v-row>
          </v-col>

          <template v-if="reserve.items && reserve.items.length > 0">
            <v-col cols="12">
              <v-row>
                <v-col class="contentBold col-4">
                  {{ $t("reservationdetails.reserveDateTimeTitle") }}
                </v-col>
                <v-col class="col-8">
                  {{ reserve.items[0].starts_at_1 }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="reserve.items[0].starts_at_2">
              <v-row>
                <v-col class="contentBold col-4">
                  {{ $t("reservationdetails.reserveSecondDateTimeTitle") }}
                </v-col>
                <v-col class="col-8">
                  {{ reserve.items[0].starts_at_2 }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="reserve.items[0].starts_at_3">
              <v-row>
                <v-col class="contentBold col-4">
                  {{ $t("reservationdetails.reserveThirdDateTimeTitle") }}
                </v-col>
                <v-col class="col-8">
                  {{ reserve.items[0].starts_at_3 }}
                </v-col>
              </v-row>
            </v-col>
          </template>
          <div class="mt-4"
            v-if="
              appointmentSetting &&
              appointmentSetting.user_cancelable_type == 1 &&
              (reserve.status == 'requested' || reserve.status == 'reserved')
            "
          >
            <v-btn
              :ripple="false"
              block
              depressed
              color="red"
              @click="cancelDialog = true"
            >
              {{ $t("reservationdetails.cancelReserveBtnText") }}
            </v-btn>
          </div>
          <div v-else class="mt-4">
            <p class="text-body-2 mx-3 text-grey" >
              {{ $t("reservationdetails.callToCancel") }}
            </p>
          </div>
        </v-row>

        <!-- PriceMenu -->
        <div v-if="reserve.items && reserve.items.length > 0">
          <v-divider></v-divider>
          <v-row class="headerRow mt-6">
            <v-col class="headerCol contentBold px-3 pt-6 pb-1">
              <p style="font-weight: bold">
                {{ $t("reservationdetails.reserveCourseTitle") }}
              </p>
            </v-col>
          </v-row>
          <v-row
            class="contentRow"
            v-for="menuItem in reserve.items"
            :key="menuItem.id"
          >
            <v-col class="contentBold">
              {{
                menuItem.menu
                  ? menuItem.menu.title
                  : $t("reservation.book_only")
              }}
            </v-col>
            <v-col> RM {{ menuItem.menu ? menuItem.menu.amount : 0 }} </v-col>
          </v-row>
          <v-row style="margin: 0">
            <v-col class="contentBold">
              {{ $t("reservation.totalAmount") }}
            </v-col>
            <v-col> RM {{ getMenuTotalAmount() }} </v-col>
          </v-row>
        </div>

        <div v-if="reserve.request_memo">
          <v-row class="headerRow">
            <v-col class="headerCol contentBold px-3 pt-6 pb-1">
              <p style="font-weight: bold">
                {{ $t("reservationdetails.reserveMemoTitle") }}
              </p>
            </v-col>
          </v-row>
          <v-row class="contentRow">
            <v-col>
              {{ reserve.request_memo }}
            </v-col>
          </v-row>
        </div>
      </v-container>

      <v-divider></v-divider>

      <!-- ShopInfo -->
      <v-row class="headerRow mt-6">
        <v-col class="headerCol contentBold px-7 pt-6 pb-1">
          <p style="font-weight: bold">
            {{ $t("reservationdetails.shopInfoTitle") }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mx-4 align-center mt-6 mb-6">
        <v-col cols="12" sm="3" class="contentBold">
          {{ $t("reservationdetails.shopNameTitle") }}
        </v-col>
        <v-col cols="12" sm="7">
          <a
            v-if="reserve.shop != null"
            v-html="reserve.shop.name"
            class="text-body-1"
            style="font-weight: normal; text-decoration: underline"
            @click="shopClick()"
          ></a>
        </v-col>
        <v-col cols="12" sm="1"></v-col>

        <v-col cols="12" sm="3" class="contentBold">
          {{ $t("reservationdetails.shopAddressTitle") }}
        </v-col>
        <v-col cols="12" sm="7">
          {{ shopDetail.address }}
        </v-col>
        <v-col cols="12" sm="1">
          <a
            :href="'http://maps.google.com/?q=' + shopDetail.address"
            rel="noopener noreferrer"
            target="_blank"
            v-if="shopDetail.address"
          >
            <v-icon color="black">mdi-map-marker</v-icon>
          </a>
        </v-col>

        <v-col cols="12" sm="3" class="contentBold pb-0">
          {{ $t("reservationdetails.shopTelTitle") }}
        </v-col>
        <v-col cols="12" sm="7" class="pb-0">
          {{ shopDetail.phone }}
        </v-col>
        <v-col cols="12" sm="1"></v-col>
      </v-row>
    </v-sheet>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-sheet height="190" class="text-center">
        <v-progress-circular
          :indeterminate="loadingDialog"
          :size="100"
          color="blue"
          class="mt-12"
        >
          {{ $t("homeview.loading") }}
        </v-progress-circular>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="cancelDialog" width="auto">
      <v-card>
        <v-card-text>
          {{ $t("reservationdetails.cancelModalTitle") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="cancelDialog = false">
            {{
              $t("crowdfundingsCheckout.confirmQuitPurchaseDialogBtnNo")
            }}</v-btn
          >
          <v-btn color="black" @click="cancelReservation">
            {{ $t("crowdfundingsCheckout.confirmQuitPurchaseDialogBtnYes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<style scoped>
.user-qr__content {
  text-align: center;
}

.contentRow {
  margin: 12px 0;
}

.contentBold {
  font-weight: bolder;
}

.tax {
  font-size: 1.2rem;
}

.center {
  display: flex;
  align-items: center;
}

.info-caption {
  line-height: 0.5px;
}

.shop-picture {
  width: 550px;
  border-radius: 16px;
}

.reserveBtn {
  background-color: #fff !important;
  width: 100%;
  color: black;
  font-size: 14px;
  display: inline-block;
  border: 1px solid red;
  padding-top: 10px;
  padding-bottom: 10px;
}

.reserveBtn-box {
  width: 100%;
}
</style>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisited: false,
      isCancel: false,
      dialog: false,
      reserve: {},
      shopDetail: {
        address: null,
        station: null,
        phone: null,
      },
      appointmentSetting: null,
      shopPic: "",
      loadingDialog: true,
      cancelDialog: false,
    };
  },
  async mounted() {
    await this.fetchReservationDetails();
  },
  methods: {
    ...mapActions({
      fetchReservationDetail: "reservationStore/fetchReservationDetail",
    }),
    getStatusMessage(status) {
      switch (status) {
        case "requested":
          return this.$t("reservationdetails.pending");
        case "reserved":
          return this.$t("reservationdetails.reserved");
        case "canceled":
          return this.$t("reservationdetails.canceled");
        case "reserve-canceled":
          return this.$t("reservationdetails.reserveCanceled");
        case "visited":
          return this.$t("reservationdetails.visited");
        case "request-expired":
          return this.$t("reservationdetails.requestExpired");
        case "reserve-expired":
          return this.$t("reservationdetails.reserveExpired");
        default:
          return status;
      }
    },
    getChipColorClass(status) {
      switch (status) {
        case "requested":
          return "#3a96b7";
        case "reserved":
          return "#91b73a";
        case "visited":
          return "#007ac1";
        case "canceled":
        case "reserve-canceled":
        case "request-expired":
        case "reserve-expired":
          return "616161";
        default:
          return "";
      }
    },
    async fetchReservationDetails() {
      const params = {
        user_reservation_id: this.id,
      };

      await this.$store
        .dispatch("reservationStore/fetchReservationDetail", params)
        .then((response) => {
          if (response) {
            this.loadingDialog = false;
            this.reserve = response;
            if (this.reserve.shop) {
              if (this.reserve.shop.detail) {
                this.shopDetail = this.reserve.shop.detail;
              }
              if (this.reserve.shop.company_appointment_setting) {
                this.appointmentSetting =
                  this.reserve.shop.company_appointment_setting;
              }
            }
            if (
              response.shop.pictures.length &&
              response.shop.pictures.length > 0
            ) {
              this.shopPic = response.shop.pictures[0].picture_url;
            }
          }
        })
        .catch((error) => {
          this.loadingDialog = false;
          this.backToHome();
          alert(error);
        });
    },
    getMenuTotalAmount() {
      let result = 0;
      if (this.reserve && this.reserve.items && this.reserve.items.length > 0) {
        this.reserve.items.forEach((v) => {
          if (v.menu) {
            result += parseFloat(v.menu.amount);
          }
        });
      }
      return result;
    },
    getAppointmentStaffName() {
      let result = this.$i18n.t("reservationdetails.nominateNo");
      if (this.reserve && this.reserve.staff) {
        result = this.reserve.staff.display_name;
      }
      return result;
    },
    async cancelReservation() {
      this.loadingDialog = true;
      this.cancelDialog = false;
      const params = {
        user_reservation_id: this.id,
      };
      await this.$store
        .dispatch("reservationStore/cancelReserve", params)
        .then((response) => {
          location.reload();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },
    shopClick() {
      this.$router.push({
        name: "ShopBySlugV2",
        params: { shop_slug: this.reserve.shop.shop_slug },
      })
    },
    backToHome() {
      this.$router.push({ name: "HomeView" });
    },
  },
};
</script>