// https://github.com/fgr-araujo/vue-breadcrumbs/blob/6c743c5d248a816c36055cd5ae18939d0760fb33/src/router/index.js
// https://medium.com/@fagnersaraujo/automated-breadcrumbs-with-vuejs-7e1051de8028

<template>
  <v-sheet class="breadcrumb pa-0 ma-0 mx-auto" height="35px" color="white">
    <v-breadcrumbs
      class="mt-n1 scrollable-breadcrumbs"
      :items="breadCrumbsProps.data"
      divider=""
      density="compact"
    >
      <template v-slot:item="{ item, index }">
        <v-icon
          v-if="index !== 0"
          icon="mdi-chevron-right"
          size="x-small"
        ></v-icon>
        <component
          :is="item.to ? 'router-link' : 'span'"
          v-bind="item.to ? { to: item.to, exact: true, tag: 'span' } : {}"
          class="text-body-2"
          :class="{
            'disabled-link': item.disabled,
            'v-breadcrumbs-item--link': item.to,
          }"
        >
          <div
            class="text-caption"
            style="font-weight: normal"
            v-snip="{ lines: 1 }"
          >
            {{ $t(item.name) }}
          </div>
        </component>
      </template>
    </v-breadcrumbs>
  </v-sheet>
</template>

<script>
export default {
  props: {
    breadCrumbsProps: Object,
  },

  computed: {
    breadCrumbs() {
      console.log( "breadCrumbs")
      if (typeof this.$route.meta.breadCrumb === "function") {
        return this.$route.meta.breadCrumb.call(this, this.$route);
      }
      return this.$route.meta.breadCrumb;
    },
  },
};
</script>

<style scoped>
.scrollable-breadcrumbs {
  display: flex; /* Makes it a flex container */
  overflow-x: auto; /* Allows horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of items */
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on touch devices */
}

.scrollable-breadcrumbs::-webkit-scrollbar {
  height: 6px; /* Custom scrollbar height */
}

.scrollable-breadcrumbs::-webkit-scrollbar-thumb {
  background-color: #b3b3b3; /* Custom scrollbar thumb color */
  border-radius: 3px;
}

.scrollable-breadcrumbs::-webkit-scrollbar-track {
  background-color: transparent; /* Custom scrollbar track */
}
</style>