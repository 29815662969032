<template>
  <v-app>
    <Navibar v-if="!this.$route.meta.hideNavbar" />
    <v-content fill-height>
      <!-- <breadcrumbs /> -->
      <router-view />
    </v-content>
    <Footer v-if="!this.$route.meta.hideFotbar" />
  </v-app>
</template>

<script>
import Navibar from "@/components/NaviBar";
import Footer from "@/components/Footer";
import { useHead } from "@vueuse/head";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "App",
  setup() {},

  components: { Navibar, Footer, Breadcrumbs },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.$cookies.set("locale", newVal,"1y");
      if (newVal === 'zhCN') {
        document.getElementsByTagName('html')[0].lang = 'zhCN'
      } else if (value === 'ms') {
        document.getElementsByTagName('html')[0].lang = 'ms'
      } else {
        document.getElementsByTagName('html')[0].lang = 'en'
      }
    },
  },

  beforeMount() {},
  created() {
    useHead({
      title: this.$i18n.tc("homeview.site_title"),
      meta: [
        {
          name: "description",
          content: this.$i18n.tc("homeview.og_desc"),
        },
        {
          name: "apple-mobile-web-app-title",
          content: "Beauty 360",
        },
        {
          name: "application-name",
          content: "Beauty 360",
        },
        {
          property: "og:site_name",
          content: "Beauty 360",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:locale:alternate",
          content: "zh_CN",
        },
        {
          property: "og:locale:alternate",
          content: "ms_MY",
        },
        {
          property: "og:title",
          content: "Beauty 360 | "+ this.$i18n.tc("homeview.og_title"),
        },
        {
          property: "og:image",
          content:
            "https://storage.googleapis.com/beauty360-dev/shop/1/2024112022524140.jpg",
        },
        {
          property: "og:image:width",
          content: "375",
        },
        {
          property: "og:image:height",
          content: "281",
        },
        {
          name: "keywords",
          content: this.$i18n.tc("homeview.og_keyword"),
        },
      ],
    });
  },
};
</script>

<style>
.v-application {
  font-family: 'Noto Sans SC', 'PingFang SC', 'Microsoft YaHei', 'Arial', sans-serif;
  font-weight: 500; /* Adjust as needed */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


</style>