<template>
  <br />
  <v-sheet class="pa-0 ma-0">
    <v-list-item class="mt-0">
      <template v-slot:prepend>
        <v-img src="/gallery.png" :width="30"></v-img>
      </template>
      <div class="d-flex align-center justify-space-between">
        <h2 class="text-h5 mx-2" style="font-weight: bold">
          {{ $t("shop.random_gallery") }}
        </h2>
      </div>
    </v-list-item>

    <v-container>
      <v-col cols="12">
        <v-row justify="center" no-gutters>
          <v-btn-toggle v-model="selected" rounded="xl" color="deep-purple-accent-3" group density="compact"
            variant="outlined" mandatory @click="change(selected)">
            <v-btn value="" size="small" :ripple="false">
              {{ $t("shop.all") }}
            </v-btn>
            <v-btn value="shop_work" size="small" :ripple="false">
              {{ $t("shop.work") }}
            </v-btn>
            <v-btn value="shop_interior" size="small" :ripple="false">
              {{ $t("shop.shop") }}
            </v-btn>
            <v-btn value="shop_other" size="small" :ripple="false">
              {{ $t("shop.other") }}
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-col>

      <v-row no-gutters warp justify="center" class="mt-2">
        <v-col v-for="(image, index) in shops" :key="image.id" class="d-flex child-flex"
          :cols="this.isMobileDevice.value ? 4 : 3">
          <v-img :src="image.picture_url_small" :lazy-src="image.picture_url_small" aspect-ratio="1" cover
            class="bg-grey-lighten-2" @click="imagePreviewClick(index)" alt="">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>

    <!-- <div align="center">
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
        >{{ $t("homeview.show_more") }}</v-btn
      >
    </div> -->
  </v-sheet>

  <v-dialog v-model="fullImage">
    <v-card class="mx-auto" :width="isMobileDevice.value ? '300px' : '80%'" height="90%">
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn :ripple="false" icon="mdi-close" color="black" width="30" height="30" variant="text"
          @click="fullImage = false">
        </v-btn>
      </v-toolbar>
      <div align="center">
        <v-img class="full-image" :src="selectedShop.picture_url_small" :lazy-src="local_logo"
          :width="isMobileDevice.value ? '300px' : '90%'" height="320" aspect-ratio="1" :alt="selectedShop.shop.name">
        </v-img>
      </div>
      <v-card-title>
        <v-icon class="mx-2" color="black" icon="mdi-store" size="16"></v-icon>
        <span class="text-body-1" style="font-weight: normal">
          {{ selectedShop.shop.name }}
        </span>
        <br />
        <v-icon icon="mdi-map-marker" color="black" size="16" class="mx-2" />
        <span class="text-black" style="font-size: 12px">
          {{ $t(prefectureCodeToString(selectedShop.shop.prefecture)) }}
        </span>
      </v-card-title>

      <v-card-subtitle> </v-card-subtitle>

      <v-card-text style="font-size: 12px" class="mt-n6">
        <a v-html="selectedShop.shop.detail.address" class="text-caption"
          style="font-weight: normal; text-decoration: underline"
          @click="callMap(selectedShop.shop.detail.address)"></a>

        <br />
        <v-chip-group class="mt-4" v-if="selectedShop.shop && selectedShop.shop.tags && selectedShop.shop.tags.length > 0">
          <v-chip v-for="i in selectedShop.shop.tags" :key="i" variant="text" class="ma-0" size="small"
            @click="chipClick(i)" style="font-weight: bold">
            #{{ stringReplace(i) }}
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <v-card-actions>
        <v-btn block class="mt-2" color="green" variant="outlined" :ripple="false" height="50"
          @click="shopDetailOnClickSlug()">
          {{ $t("shop.shop_details") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uitls from "@/uitls/Uitls";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";

export default {
  components: {
    VueBottomSheet,
  },

  data() {
    return {
      selected: 0,
      shops: [],
      images: [],
      local_logo: require("@/assets/img/logo120.png"),
      fullImage: false,
      selectedShop: null,
    };
  },

  computed: {
    selected: {
      get() {
        return this.selected;
      },
      set(v) {
        this.selected = v;
        this.$forceUpdate();
      },
    },
  },

  mounted() {
    this.getShopPictures("");
  },

  methods: {
    change(val) {
      this.getShopPictures(val);
    },

    callMap(add) {
      window.open("https://maps.google.com/?q=" + add, "_self");
    },

    imagePreviewClick(index) {
      this.selectedShop = this.shops[index];
      this.fullImage = true;
    },

    async getShopPictures(value) {
      const params = {
        limit: this.isMobileDevice.value ? 20 : 40,
        category: value ? value : "",
      };

      await this.$store
        .dispatch("shopStore/fetchRandomShopPictures", params)
        .then((response) => {
          this.shops = response;

          for (let index = 0; index < this.shops.length; index++) {
            const element = this.shops[index].picture_url_small;
            this.images.push(element);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    // shopDetailOnClick(shopID) {
    //   this.$router.push({
    //     name: "Shop",
    //     params: { id: shopID },
    //   })
    // },
    shopDetailOnClickSlug() {
      // console.log(this.selectedShop)
      if (this.selectedShop && this.selectedShop.shop) {
        if (this.selectedShop.shop.shop_slug) {
          this.$router.push({
            name: "ShopBySlugV2",
            params: { shop_slug: this.selectedShop.shop.shop_slug },
          })
        } else {
          this.$router.push({
            name: "Shop",
            params: { id: this.selectedShop.shop_id },
          })
        }
      }
    },

    prefectureCodeToString(code) {
      return uitls.prefectureCodeToString(code);
    },

    stringReplace(string) {
      return string
        .replace(/["']/g, "")
        .replace("[", "")
        .replace("]", "")
        .trim();
    },

    chipClick(value) {
      const searchFilterObjects = {
        searchKeyword: value,
        prefecture_v2: 0,
        priceRange: [0, 1000],
      };

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },
  },
};
</script>