<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <br />
    <v-list-item class="mt-0">
      <template v-slot:prepend>
        <v-img src="/magazine.png" :width="30"></v-img>
      </template>
      <div class="d-flex align-center justify-space-between">
        <h2 class="text-h5 mx-2" style="font-weight: bold">
          {{ $t("homeview.new_topics") }}
        </h2>
      </div>
    </v-list-item>
    
    <p class="ma-4 text-body-2" style="font-weight: normal">
      {{ $t("article.list_desc") }}
    </p>

    <!-- Category Tag -->
    <v-slide-group center-active active-class="indigo" v-model="selection">
      <v-slide-group-item
        v-for="(item, index) in articleCategories"
        :key="item.name"
        v-slot="{ isSelected }"
      >
        <v-btn
          class="ma-2"
          rounded
          prepend-icon="mdi-bookmark"
          elevation="0"
          :color="isSelected ? 'blue' : 'grey'"
          variant="outlined"
          :ripple="false"
          @click="categoryOnClick(item.id, index)"
        >
          {{ $t("utls." + item.name) }}
        </v-btn>
      </v-slide-group-item>
    </v-slide-group>

    <v-text-field
      class="ma-4"
      variant="outlined"
      v-model="searchKeyword"
      single-line
      hide-details
      clearable
      prepend-inner-icon="mdi-magnify"
      @keydown.enter.prevent="startSearchOnClick"
    >
      <v-field-label style="font-weight: normal">{{
        $t("searchfilter.keyword")
      }}</v-field-label>
    </v-text-field>

    <!-- ArticleList -->
    <v-container fluid class="mt-4">
      <v-row>
        <v-col
          v-for="item in list"
          :key="item.id"
          cols="12"
          md="6"
          xs="12"
          sm="12"
        >
          <ArticleTableCellCard
            :articleObjects="item"
            @articleIDCallBack="articleIDCallBack"
            @articleDetailOnClickBySlug="articleDetailOnClickBySlug"
          ></ArticleTableCellCard>
        </v-col>
      </v-row>
    </v-container>
    <!-- <input @click="getNextArticle" type="button" value="getNextArticle" /> -->

    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="totalPages"
        rounded="circle"
      ></v-pagination>
    </div>

    <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" />
  </v-sheet>

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
      >
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>
</template>


<script>
import { useHead } from "@vueuse/head";
import { mapState, mapActions } from "vuex";
import Constants from "@/uitls/Constants";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArticleTableCellCard from "@/components/ArticleTableCellCard.vue";

export default {
  components: {
    Breadcrumbs,
    ArticleTableCellCard,
  },

  computed: {
    ...mapState({
      article: (state) => state.ArticleStore.article,
    }),

    breadCrumbsProps: function () {
      return {
        data: [
          { name: "mypage.topPage", to: "/" },
          { name: "homeview.new_topics" },
        ],
      };
    },
  },

  watch: {
    page(newPage) {
      if (this.selection == 0) {
        this.getArticleList();
      } else {
        this.getArticleCategoryList(this.categoryID);
      }
    },
  },

  data() {
    return {
      list: [],
      selection: 0,
      page: 1,
      no_IMAGE: Constants.NO_IMAGE,
      articleCategories: null,
      searchKeyword: "",
      totalPages: 1,
      loadingDialog: true,
      hairStyleArticleCount: 0,
      lifestyleArticleCount: 0,
      categoryID: 0,
    };
  },

  beforeMount() {},

  mounted() {
    if (this.selection == 0) {
      this.getArticleList();
    }
    this.setMetaInfo();
  },

  methods: {
    setMetaInfo() {
      useHead({
        title: this.$i18n.tc("article.og_title"),
        meta: [
          {
            name: "robots",
            content: "index,follow",
          },
          {
            name: "description",
            content: this.$i18n.tc("article.og_description"),
          },
          {
            name: "og:type",
            content: "article",
          },
          {
            property: "og:title",
            content: this.$i18n.tc("article.og_title"),
          },
          {
            property: "og:description",
            content: this.$i18n.tc("article.og_description"),
          },
          {
            property: "og:url",
            content: "https://beauty-360.com/topic",
          },
          {
            property: "og:image",
            content:
              "https://media.beauty-360.com/wp-content/uploads/2024/11/banner11.jpg",
          },
          {
            property: "og:image:width",
            content: "375",
          },
          {
            property: "og:image:height",
            content: "196",
          },
        ],
      });
    },

    articleIDCallBack(articleID) {
      this.$router.push({
        name: "ArticleViewDetail",
        params: { id: articleID },
      });
    },
    articleDetailOnClickBySlug(articleSlug) {
      this.$router.push({
        name: "ArticleViewDetailBySlug",
        params: { article_slug: articleSlug },
      })
    },

    async categoryOnClick(categoryID, index) {
      this.selection = index;
      if (this.selection == 0) {
        this.getArticleList();
      } else {
        this.categoryID = categoryID;
        this.getArticleCategoryList(this.categoryID);
      }
    },

    async getArticleList() {
      this.loadingDialog = true;
      this.list = null;
      this.totalPages = 0;

      await this.$store
        .dispatch("ArticleStore/getWordPressArticleList2", this.page)
        .then((response) => {
          this.loadingDialog = false;
          this.list = response;
        })
        .catch((error) => {
          this.loadingDialog = false;
          this.$router.push("/404");
        });

      const array2 = await this.getWordPressCategories({
        per_page: 100,
      });

      this.hairStyleArticleCount = parseInt(array2[0].count);
      this.lifestyleArticleCount = parseInt(array2[1].count);

      const allTotalArticleCount =
        (this.hairStyleArticleCount + this.lifestyleArticleCount) / 3; // 3 languages
      this.totalPages = Math.ceil(allTotalArticleCount / 10) + 1;

      //Add all category
      const array1 = [
        {
          id: 0,
          name: "all",
        },
      ];
      this.articleCategories = array1.concat(array2);
    },

    async getArticleCategoryList(categoryID) {
      this.loadingDialog = true;
      this.list = null;
      this.totalPages = 0;

      console.log(categoryID);

      await this.$store
        .dispatch("ArticleStore/getWordPressCategoryList", {
          categories: categoryID,
          _embed: true,
          per_page: 10,
          page: this.page,
        })
        .then((response) => {
          this.loadingDialog = false;
          this.list = response;
        })
        .catch((error) => {
          this.loadingDialog = false;
          this.$router.push("/404");
        });

      let allTotalArticleCount = null;
      switch (categoryID) {
        case 1:
          allTotalArticleCount = this.hairStyleArticleCount / 3;
          this.totalPages = Math.ceil(allTotalArticleCount / 10);
          break;
        case 406:
          allTotalArticleCount = this.lifestyleArticleCount / 3;
          this.totalPages = Math.ceil(allTotalArticleCount / 10);
          break;
        default:
          break;
      }
      console.log("allTotalArticleCount " + allTotalArticleCount);
    },

    getWordPressCategories(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("ArticleStore/getWordPressCategories", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            throw new Error(error);
          });
      });
    },

    startSearchOnClick() {
      this.$router.push({
        name: "ArticleSearch",
        params: { keyword: this.searchKeyword },
      });
    },

    // getNextArticle() {
    //   window.onscroll = () => {
    //     const scrollPosition = window.scrollY + window.innerHeight;
    //     let bottomOfWindow = scrollPosition >= document.body.offsetHeight - 50;
    //     if (bottomOfWindow) {
    //       this.page = this.page + 1;
    //       ArticleStore.dispatch("getStory", this.page).then(
    //         (response) => {
    //           if (ArticleStore.state.property.list.length > 0) {
    //             this.list.push.apply(ArticleStore.state.property.list);
    //           }
    //         },
    //         (error) => {
    //           console.error("Nothing from server.");
    //         }
    //       );
    //     }
    //   };
    // },
  },
};
</script>

<style scoped>
.text-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}
</style>