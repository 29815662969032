<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-img
      v-if="targetPrefecture"
      :src="targetPrefecture.image"
      class="align-center"
      gradient="to bottom, rgba(0,0,0,.6), rgba(0,0,0,.9)"
      max-height="400px"
      aspect-ratio="16/9"
      cover
    >
      <p class="text-h4 mt-4 font-weight-bold text-center text-white">
        {{ generatePrefecture() }}
      </p>
      <p class="text-body-2 text-white text-center mt-2 pa-4">
        {{ generatePrefectureDesc() }}
      </p>
    </v-img>
    <h2 class="text-h5 mx-2 mt-4" style="font-weight: bold">
      {{ generateTitle() }}
    </h2>

    <v-container>
      <v-row>
        <v-col>
          <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          v-for="(item, index) in dataList"
          :key="index"
          cols="6"
          md="4"
          sm="2"
        >
          <v-card
            class="mx-auto pa-2"
            max-width="480"
            height="200"
            elevation="0"
            @click="cardOnClick(item)"
          >
            <v-img
              :src="item.image"
              class="align-center"
              gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.9)"
              height="200px"
              cover
              v-if="item.image"
            >
              <p class="text-center text-h6 text-white">
                {{ item.name }}
              </p>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
      >
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>

  <VSonner position="top-center" />
</template>

<script>
import { VSonner } from "vuetify-sonner";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  props: ["prefecture", "city"],
  components: {
    VSonner,
    Breadcrumbs,
  },

  watch: {
    async $route(to, from) {
      this.loadingDialog = true;
      await this.getDataList();
    },
  },

  computed: {
   
  },

  data() {
    return {
      loadingDialog: true,
      dataList: [],
      breadCrumbsProps: [],
      targetPrefecture: null,
      targetCity: null,
    };
  },

  async mounted() {
    await this.getDataList();
  },

  methods: {
    generatePrefecture() {
      if (this.targetPrefecture != null) {
        return this.$i18n.t("prefecture." + this.targetPrefecture.code);
      }
    },

    generatePrefectureDesc() {
      if (this.targetPrefecture != null) {
        return this.$i18n.t("areaview." + this.targetPrefecture.code);
      }
    },

    generateTitle() {
      let title = "";
      if (this.targetCity != null) {
        title =
          this.$i18n.t("utls.hairSalonAt") +
          this.$i18n.t("city." + this.targetCity.code);
      } else {
        if (this.targetPrefecture != null) {
          title =
            this.$i18n.t("utls.hairSalonAt") +
            this.$i18n.t("prefecture." + this.targetPrefecture.code);
        }
        else{
        return this.$i18n.t("homeview.find_your_salon" );
      }
      }
      return title;
    },

    async getDataList() {
      const vm = this;
      vm.dataList = [];

      await vm.$store
        .dispatch("shopStore/getShopCityList", {
          prefecture_v2_name: vm.prefecture,
          city_name: vm.city,
        })
        .then(async (response) => {
          // console.log(response)
          if (response && response.data && response.data.length > 0) {
            await vm.handleDataList(response.data);
            // console.log('dataList', vm.dataList)
            this.handleBreadcrumbs();
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          vm.loadingDialog = false;
        });
    },

    handleDataList(source) {
      // console.log('source', source)
      // console.log('this.prefecture', this.prefecture)
      // console.log('this.city', this.city)
      this.targetPrefecture = null;
      this.targetCity = null;
      if (this.prefecture && this.city) {
        const targetPrefecture = source.find(
          (item) => item.name == this.prefecture
        );
        // console.log('targetPrefecture', targetPrefecture)
        if (targetPrefecture && targetPrefecture.city_list) {
          this.targetPrefecture = targetPrefecture;
          const targetCity = targetPrefecture.city_list.find(
            (item) => item.name == this.city
          );
          // console.log('targetCity', targetCity)
          if (targetCity) {
            this.targetCity = targetCity;
            this.dataList = targetCity.shops.map((item) => {
              return {
                type: "shop",
                id: item.id,
                name: item.name,
                value: item.shop_slug,
                image: item.image
                  ? item.image
                  : "https://storage.googleapis.com/beauty360-dev/prefecture/allarea.jpg",
              };
            });
          }
        }
      } else if (!this.prefecture && !this.city) {
        this.dataList = source.map((item) => {
          return {
            type: "prefecture",
            id: item.id,
            name: item.code
              ? this.$i18n.t("prefecture." + item.code)
              : item.name,
            value: item.name,
            image: item.image
              ? item.image
              : "https://storage.googleapis.com/beauty360-dev/prefecture/allarea.jpg",
          };
        });
      } else if (this.prefecture && !this.city) {
        const targetPrefecture = source.find(
          (item) => item.name == this.prefecture
        );
        // console.log('targetPrefecture', targetPrefecture)
        if (targetPrefecture) {
          this.targetPrefecture = targetPrefecture;
          this.dataList = targetPrefecture.city_list.map((item) => {
            return {
              type: "city",
              id: item.id,
              name: item.code ? this.$i18n.t("city." + item.code) : item.name,
              value: item.name,
              image: item.image
                ? item.image
                : "https://storage.googleapis.com/beauty360-dev/prefecture/allarea.jpg",
            };
          });
        }
      }
    },

    async cardOnClick(item) {
      switch (item.type) {
        case "prefecture":
          this.$router.replace({
            name: "PrefectureCityShopList",
            params: { prefecture: item.value },
          });
          break;
        case "city":
          this.$router.replace({
            name: "PrefectureCityShopList",
            params: { prefecture: this.prefecture, city: item.value },
          });
          break;
        case "shop":
          this.$router.replace({
            name: "ShopBySlugV2",
            params: { shop_slug: item.value },
          });
          break;
      }
      this.loadingDialog = true;

      setTimeout(async () => {
        await this.getDataList();
      }, 1000);
    },

    handleBreadcrumbs() {
      const vm = this;
      // console.log('handleBreadcrumbs')
      vm.breadCrumbsProps = {
        data: [
          {
            name: "mypage.topPage",
            to: "/",
            disabled: false,
          },
        ],
      };
      console.log("handleBreadcrumbs targetPrefecture", vm.targetPrefecture);
      // console.log("handleBreadcrumbs targetCity", vm.targetCity);
      if (!vm.targetPrefecture && !vm.targetCity) {
        vm.breadCrumbsProps.data.push({
          name: "utls.hair_salon",
          disabled: true,
        });
      } else if (vm.targetPrefecture && !vm.targetCity) {
        vm.breadCrumbsProps.data.push({
          name: "prefecture.all_area",
          to: "/hairsalon",
          disabled: true,
        });
        vm.breadCrumbsProps.data.push({
          name: vm.$i18n.t("utls.cityAt", {
            name: vm.$i18n.t("prefecture." + vm.targetPrefecture.code),
          }),
          disabled: true,
        });
      } else if (vm.targetPrefecture && vm.targetCity) {
        vm.breadCrumbsProps.data.push({
          name: "prefecture.all_area",
          to: "/hairsalon",
          disabled: true,
        });
        vm.breadCrumbsProps.data.push({
          name: "prefecture." + vm.targetPrefecture.code,
          to: "/hairsalon/" + vm.prefecture,
          disabled: false,
        });
        vm.breadCrumbsProps.data.push({
          name: vm.$i18n.t("utls.hairSalonAt", {
            name: vm.$i18n.t("city." + vm.targetCity.code),
          }),
          disabled: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.v-chip {
  overflow: initial;
  color: #2196f3;
}

.v-select__selections {
  overflow: scroll;
  flex-wrap: nowrap;
}
</style>