<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <!-- Btn -->
    <!-- <v-row class="mx-4 mt-3">
      <v-btn
        :ripple="false"
        color="black"
        variant="text"
        prepend-icon="mdi-format-list-bulleted-square"
        value="left"
        @click="popularOnclick"
      />

      <v-spacer />

      <v-btn
        :ripple="false"
        color="black"
        variant="text"
        prepend-icon="mdi-thumb-up"
        value="left"
        @click="popularOnclick"
      >
        {{ $t("crowdfunding.popular") }}
      </v-btn>

      <v-btn
        :ripple="false"
        value="right"
        variant="text"
        color="black"
        prepend-icon="mdi-creation"
        @click="latestOnclick"
      >
        {{ $t("crowdfunding.latest") }}
      </v-btn>
    </v-row> -->
    <br />
    <v-list-item class="mt-0">
      <template v-slot:prepend>
        <v-img src="/discounts.png" :width="30"></v-img>
      </template>
      <div class="d-flex align-center justify-space-between">
        <h2 class="text-h5 mx-2" style="font-weight: bold">
          {{ $t("homeview.promotion") }}
        </h2>
      </div>
    </v-list-item>

    <!-- Card -->
    <v-row no-gutters class="mb-16 mt-6" justify="left">
      <v-col
        cols="6"
        md="3"
        class="pa-2"
        v-for="(item, index) in listDetail"
        :key="item.name"
      >
        <v-card
          class="event-card mx-auto mb-4"
          max-width="280"
          :ripple="false"
          @click="cardOnClick(index)"
        >
          <v-img
            height="140px"
            :lazy-src="local_logo"
            :src="this.getImageUrl(item.picture_path)"
            cover
          >
            <div class="date-ribbon">
              <p class="text-body-2" font-weight: bold>OFF</p>
              <p class="text-body-2" style="font-weight: bold">
                {{ item.discount_rate.toFixed(0) }}%
              </p>
            </div>
          </v-img>

          <v-card-text>
            <div
              class="text-body-2 mt-0"
              v-snip="{ lines: 2 }"
              style="color: rgb(51, 51, 51)"
            >
              {{ item.name }}
            </div>
          </v-card-text>

          <v-card-subtitle class="mt-n3">
            <v-icon icon="mdi-map-marker" size="14" color="black" />
            <span
              class="text-black mx-2"
              style="font-size: 13px; font-weight: normal"
            >
              {{ $t(prefectureCodeToString(item.shopPrefecture)) }}
            </span>
          </v-card-subtitle>

          <v-card-text>
            <div
                  v-if="
                    item.percentage ==
                    null
                  "
                >
            <p class="mt-0 text-body-2" style="color: rgb(160, 160, 160)">
              <span class="text-h5" style="color: #fe453e">
                <strong
                  >RM
                  {{ item.price }}
                </strong>
              </span>
              &nbsp;
              <strike> RM{{ item.original_price }} </strike>
            </p>
          </div>
          
          <div v-else>
            <p class="text-h6" style="color: #fe453e">
                    <strong
                      >{{
                        item.percentage
                      }}% OFF
                    </strong>
                  </p>
          </div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="grey"
              :ripple="false"
              text
              @click.stop="exploreClick()"
              >{{ $t("homeview.show_more") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              @click.stop="exploreClick()"
            ></v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                <p class="text-body-1" style="font-weight: bold">
                  {{ item.shopName }}
                </p>
                <p class="mt-2 text-body-1">{{ item.name }}</p>
                <p
                  class="mt-2 text-caption"
                  v-html="replaceText(item.description)"
                ></p>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <!-- ListLayout -->
    <!-- <CrowdfundingsList
      :pageTitle="$t('homeview.special_voucher')"
      :isCrowdfoundingDetail="true"
      :showItemCount="100"
    /> -->
  </v-sheet>
</template>

<script scope>
import CrowdfundingsList from "@/components/CrowdfundingsList.vue";
import { useHead } from "@vueuse/head";
import Constants from "@/uitls/Constants";
import Uitls from "@/uitls/Uitls.js";
import { mapState, mapActions, mapGetters } from "vuex";
import { useConfirm, useSnackbar } from "vuetify-use-dialog";

export default {
  components: {
    CrowdfundingsList,
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });

    confirm = useConfirm();
  },

  data() {
    return {
      show: false,
      list: [],
      listDetail: [],
      local_logo: require("@/assets/img/logo120.png"),
    };
  },
  beforeMount() {},

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
    }),
  },

  async mounted() {
    const params = {
      page: 1,
      per_page: 10,
      sort: "-id",
    };

    await this.$store
      .dispatch("crowdStore/fetchCrowds", params)
      .then((response) => {
        this.list = response.data;
        for (let i = 0; i < this.list.length; i++) {
          let shopId = this.list[i].shop.id;
          let shopName = this.list[i].shop.name;
          let shopPrefecture = this.list[i].shop.prefecture;
          let crowdfunding_items = this.list[i].crowdfunding_items;

          crowdfunding_items.forEach((item) => {
            item.shopId = shopId;
            item.shopName = shopName;
            item.shopPrefecture = shopPrefecture;
          });

          this.listDetail.push(...crowdfunding_items);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    popularOnclick() {},
    latestOnclick() {},
    prefectureCodeToString(code) {
      return Uitls.prefectureCodeToString(code);
    },
    getImageUrl(imagePath) {
      return new URL(Constants.IMAGE_URL + imagePath).href;
    },

    async cardOnClick(index) {
      if (!this.isAuthenticated) {
        const result = await confirm({
          content: this.$i18n.t("shop.pls_login_usevoucher"),
          confirmationText: this.$i18n.t("loginpage.sign_become"),
          cancellationText: this.$i18n.t("usevoucher.cancel"),
          dialogProps: {
            persistent: true,
            width: 400,
          },
        });

        if (result) {
          this.$router.push({
            name: "LoginPage",
            query: {
              redirect: "/searchvoucher",
            },
          });
        }
        return;
      } else {
        this.checkUseVoucher(index);
      }
    },

    async checkUseVoucher(index) {
      const targetItem = this.listDetail[index];
      if (targetItem) {
        const tobeSubmit = {
          crowdfunding_item_id: targetItem.id,
        };
        await this.$store
          .dispatch("userStore/addVoucher", tobeSubmit)
          .then((res) => {
            if (res) {
              this.$router.push({
                name: "UseVoucher",
                params: {
                  crowdfundingItemId: targetItem.id,
                  shopId: targetItem.shopId,
                },
              });
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },

    exploreClick() {
      this.show = !this.show;
    },

    replaceText(text) {
      var replaceText = "";
      try {
        replaceText = text.replace(/\n/g, "<br />");
      } catch (e) {
        replaceText = "";
      }
      return replaceText;
    },
  },
};
</script>

<style scoped>
.event-card .desc {
  margin-bottom: 1.2em;
  font-size: 0.16em;
  padding-left: 0.1em;
}
.event-card .date-ribbon {
  position: absolute;
  top: 0px;
  left: 1em;
  background: #fe453e;
  color: #fff;
  padding: 0em 0.3em;
  padding-bottom: 0px;
  border-radius: 0px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.event-card .date-ribbon::before,
.event-card .date-ribbon::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0%;
  height: 0px;
}

.event-card .date-ribbon::before {
  left: 0;
  border-left: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-right: solid 2em transparent;
}

.event-card .date-ribbon::after {
  right: 0;
  border-right: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-left: solid 2em transparent;
}
</style>