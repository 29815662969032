<template>
  <v-row justify="center">
    <v-dialog
      class="ma-3"
      v-model="dialog"
      max-width="960"
      @update:model-value="$emit('loginDialogCallBack', false)"
    >
      <v-sheet
        class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4"
        color="white"
        rounded="lg"
        width="100%"
        elevation="0"
        justify="center"
      >
        <div>
          <h2 class="text-h4 font-weight-black text-black mt-6">Beauty 360</h2>

          <div class="text-body font-weight-medium mb-2 mt-6">
            {{ $t("shop.pls_login") }}
          </div>

          <v-btn
            color="green"
            variant="outlined"
            class="mb-6 mt-6"
            size="large"
            :ripple="false"
            @click="loginOnclick"
          >
            {{ $t("loginpage.sign_become") }}</v-btn
          >
        </div>
      </v-sheet>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },

  methods: {
    loginOnclick() {
      this.$emit("loginDialogCallBack", true);
    },
  },
};
</script>